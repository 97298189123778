// Libs
import React, { useCallback } from 'react'
import { prop } from 'ramda'
import { useSelector } from 'react-redux'

// Models
import { IUsersSelector } from './model'
import { SessionState, sessionStoreKey } from 'core/session/sessionReducers'

// Actions
import { deleteUser } from './new-actions'
import useUpdateAction from 'core/hooks/useUpdateAction'

// Elements
import Text from 'core/elements/Text'
import Button from 'core/elements/button'
import Modal from 'core/elements/modal'

interface Props {
  rows: IUsersSelector[]
  onClose: (success) => void
}

const UserDeleteDialog: React.FC<Props> = ({ rows: [user], onClose }) => {
  if (!user) {
    return null
  }
  const session = useSelector(prop<string, SessionState>(sessionStoreKey))
  const {
    userDetails: { id = '' },
  } = session

  const isSignedInUser = user?.id === id

  const { update: deleteFn, updating: deletingUser } = useUpdateAction(deleteUser)

  const title = `Permanently delete user "${user.username}"?`

  const extraMessage = isSignedInUser
    ? [
        'Warning, you are about to delete the user associated with your current login.',
        <br />,
        'Doing so will terminate your session, you will be logged out',
        <br />,
        'and you will no longer be able to access this account.',
      ]
    : ''

  const handleDelete = useCallback(async () => {
    await deleteFn({ id: user.id })
    onClose(true) // need to put true here so that the grid refreshes
  }, [user])

  return (
    <Modal
      panel="dialog"
      open
      onClose={onClose}
      title={title}
      maxWidth={600}
      footer={
        <>
          <Button variant="secondary" onClick={onClose} loading={false} disabled={deletingUser}>
            Don't Delete
          </Button>
          <Button onClick={handleDelete} loading={deletingUser}>
            Delete {user.username}
          </Button>
        </>
      }
    >
      {extraMessage && <Text variant="body2">{extraMessage}</Text>}
      <Text variant="body2">This action cannot be undone, are you sure you want to do this?</Text>
    </Modal>
  )
}

export default UserDeleteDialog
