import React, { useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import Text from 'core/elements/Text'
import Button from 'core/elements/button'
import ToggleSwitch from 'core/elements/ToggleSwitch'
import Modal from 'core/elements/modal'

interface Props {
  themeIsEnabled: boolean
  checked: boolean
  onClick?: any
}

const useStyles = makeStyles(() => ({
  switch: {
    marginBottom: 8,
  },
}))

const ThemeToggle = ({ themeIsEnabled, checked, onClick }: Props) => {
  const classes = useStyles({})
  const [showModal, setModal] = useState(false)

  const handleOpen = () => setModal(true)
  const handleClose = () => setModal(false)

  const toggleSwitch = () => {
    if (checked && themeIsEnabled) {
      handleOpen()
      return
    }
    onClick()
  }

  const removeTheme = async () => {
    await onClick()
    handleClose()
  }

  const renderModalContent = () => (
    <Modal
      open
      panel="dialog"
      title="Remove Custom Theme"
      onClose={handleClose}
      footer={
        <>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button onClick={removeTheme}>Remove</Button>
        </>
      }
    >
      <Text variant="body2">Are you sure you would like to remove the custom theme?</Text>
    </Modal>
  )

  return (
    <div>
      {showModal && renderModalContent()}
      <ToggleSwitch
        className={classes.switch}
        active={checked}
        onClick={toggleSwitch}
        label="Enable Custom Theme"
      />
    </div>
  )
}

export default ThemeToggle
