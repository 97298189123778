import MyAccountHeader from 'account/components/secondaryHeaders/MyAccountHeader'
import SsoManagementPage from 'account/components/ssoManagement/SsoManagementPage'
import AddTenantPage from 'account/components/userManagement/tenants/AddTenantPage'
import EditTenantPage from 'account/components/userManagement/tenants/EditTenantPage'

import UserManagementIndexPage from 'account/components/userManagement/UserManagementIndexPage'
import AddUserPage from 'account/components/userManagement/users/AddUserPage'
import EditUserPage from 'account/components/userManagement/users/EditUserPage'
import { AppPlugins, userAccountPrefix } from 'app/constants'
import Plugin from 'core/plugins/plugin'
import React from 'react'
import SystemStatusPage from './components/system-status'
import CustomThemePage from './components/theme/CustomThemePage'
import UserSettingsIndexPage from './components/user-settings/user-settings-index-page'
import AddUserGroupPage from './components/userManagement/groups/AddUserGroupPage'
import EditUserGroupPage from './components/userManagement/groups/EditUserGroupPage'

const myAccountPlugin = new Plugin(AppPlugins.MyAccount, 'Settings', userAccountPrefix, 'cog')
myAccountPlugin.registerSecondaryHeader(MyAccountHeader)

myAccountPlugin.registerRoutes([
  {
    name: 'System Status',
    link: {
      path: '/status',
      exact: true,
    },
    component: SystemStatusPage,
  },
  {
    name: 'User Settings',
    link: {
      path: '/settings/:tab?',
      exact: true,
      default: true,
      defaultParams: { tab: 'profile' },
    },
    component: UserSettingsIndexPage,
  },
  {
    name: 'Tenants & Users',
    requiredRoles: 'admin',
    link: {
      path: '/access/:tab?',
      exact: true,
    },
    component: UserManagementIndexPage,
  },
  {
    name: 'Add Tenant',
    requiredRoles: 'admin',
    link: { path: '/access/tenants/add', exact: true },
    component: AddTenantPage,
  },
  {
    name: 'Edit Tenant',
    requiredRoles: 'admin',
    link: { path: '/access/tenants/edit/:id', exact: true },
    component: EditTenantPage,
  },
  {
    name: 'Add User',
    requiredRoles: 'admin',
    link: { path: '/access/users/add', exact: true },
    component: AddUserPage,
  },
  {
    name: 'Edit User',
    requiredRoles: 'admin',
    link: { path: '/access/users/edit/:id', exact: true },
    component: EditUserPage,
  },
  {
    name: 'Add User Group',
    requiredRoles: 'admin',
    link: { path: '/access/groups/add', exact: true },
    component: AddUserGroupPage,
  },
  {
    name: 'Edit User Group',
    requiredRoles: 'admin',
    link: { path: '/access/groups/edit/:id', exact: true },
    component: EditUserGroupPage,
  },
  {
    name: 'Enterprise SSO',
    requiredRoles: 'admin',
    link: {
      path: '/sso/:tab?/(edit||add)?/:id?',
      exact: true,
    },
    component: SsoManagementPage,
  },
  {
    name: 'Custom Theme',
    requiredRoles: 'admin',
    link: { path: '/theme', exact: true },
    component: CustomThemePage,
  },
])

// These nav items are in active development but not shown in production.
const navItems = [
  {
    name: 'User Settings',
    link: { path: '/settings/profile', definition: '/settings/:tab' },
    icon: 'users-cog',
  },
  {
    name: 'Tenants & Users',
    link: { path: '/access/users', definition: '/access/:tab' },
    icon: 'users',
    requiredRoles: 'admin',
    nestedLinks: [
      {
        name: 'Tenants',
        link: { path: '/access/tenants' },
      },
      {
        name: 'Users',
        link: { path: '/access/users' },
      },
      { name: 'Groups', link: { path: '/access/groups' } },
      { name: 'Roles', link: { path: '/access/roles' } },
    ],
  },
  {
    name: 'Enterprise SSO',
    link: { path: '/sso/configure', definition: '/sso/:tab' },
    icon: 'key',
    requiredRoles: 'admin',
    requiredFeatures: (features) => {
      if (!features || !features.experimental) {
        return false
      }
      // Legacy DU & DDU have different conditions
      if (features.experimental.kplane) {
        // Show user the upgrade dialog to FT users on the SSO screen itself
        return true
      }
      return features.experimental.sso
    },
  },
  {
    name: 'Custom Theme',
    link: { path: '/theme' },
    icon: 'palette',
    requiredRoles: 'admin',
    requiredFeatures: (features) => {
      if (!features || !features.experimental) {
        return false
      }
      return features.experimental.kplane
    },
  },
]

myAccountPlugin.registerNavItems(navItems)

export default myAccountPlugin
