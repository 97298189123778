import UserRolesTableField from 'account/components/userManagement/tenants/UserRolesTableField'
import { listUsers } from 'account/components/userManagement/users/new-actions'
import { usersSelector } from 'account/components/userManagement/users/selectors'
import FormWrapper from 'core/components/FormWrapper'
import Progress from 'core/components/progress/Progress'
import TextField from 'core/components/validatedForm/TextField'
import ValidatedForm from 'core/components/validatedForm/ValidatedForm'
import Wizard from 'core/components/wizard/Wizard'
import WizardStep from 'core/components/wizard/WizardStep'
import useListAction from 'core/hooks/useListAction'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { requiredValidator } from 'core/utils/fieldValidators'
import { routes } from 'core/utils/routes'
import React from 'react'
import { useSelector } from 'react-redux'
import useReactRouter from 'use-react-router'
import { createTenant } from './new-actions'

const listUrl = routes.userManagement.root.path({ tab: 'tenants' })

const wizardMetaFormattedNames = {
  name: 'Name',
  description: 'Description',
  roleAssignments: 'Role Assignments',
}

const wizardMetaCalloutFields = Object.keys(wizardMetaFormattedNames)

const initialContext = {
  name: '',
  description: '',
  roleAssignments: {},
}

const userRolesValidations = [requiredValidator.withMessage('Must select at least one user')]

const AddTenantPage = () => {
  const { history } = useReactRouter()

  const { update, updating, error: errorCreatingTenant } = useUpdateAction(createTenant)

  const submitForm = async (params) => {
    const { success } = await update(params)
    if (success) history.push(listUrl)
  }

  const { loading } = useListAction(listUsers)
  const users = useSelector(usersSelector)

  return (
    <FormWrapper title="New Tenant" loading={updating} backUrl={listUrl}>
      <Wizard onComplete={submitForm} context={initialContext} error={errorCreatingTenant}>
        {({ wizardContext, setWizardContext, onNext }) => (
          <>
            <WizardStep
              stepId="basic"
              label="Basic Info"
              summaryTitle="New Tenant"
              summaryKeyOverrides={wizardMetaFormattedNames}
              summaryCalloutFields={wizardMetaCalloutFields}
            >
              <ValidatedForm
                title="Basic Info"
                initialValues={wizardContext}
                onSubmit={setWizardContext}
                triggerSubmit={onNext}
              >
                <TextField id="name" label="Name" required />
                <TextField id="description" label="Description" />
              </ValidatedForm>
            </WizardStep>
            <WizardStep stepId="users" label="Users and Roles">
              <ValidatedForm
                title="Users and Roles"
                fullWidth
                initialValues={wizardContext}
                onSubmit={setWizardContext}
                triggerSubmit={onNext}
              >
                <Progress
                  renderContentOnMount={!loading}
                  loading={loading}
                  message={'Loading Users...'}
                >
                  <UserRolesTableField
                    validations={userRolesValidations}
                    id="roleAssignments"
                    users={users}
                  />
                </Progress>
              </ValidatedForm>
            </WizardStep>
          </>
        )}
      </Wizard>
    </FormWrapper>
  )
}

export default AddTenantPage
