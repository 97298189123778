import React, { useCallback, useEffect, useMemo, useState } from 'react'
import useReactRouter from 'use-react-router'
import { routes } from 'core/utils/routes'
import FormWrapperDefault from 'core/components/FormWrapper'
import DocumentMeta from 'core/components/DocumentMeta'
import ValidatedForm from 'core/components/validatedForm/ValidatedForm'
import { FormFieldCard } from 'core/components/validatedForm/FormFieldCard'
import Text from 'core/elements/Text'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import TenantRolesTableFieldDefault from '../users/TenantRolesTableField'
import { requiredValidator } from 'core/utils/fieldValidators'
import { listTenants } from '../../userManagement/tenants/new-actions'
import { tenantsSelector } from 'account/components/userManagement/tenants/selectors'
import Progress from 'core/components/progress/Progress'
import { listUsers } from '../users/new-actions'
import { usersSelector } from '../users/selectors'
import TextField from 'core/components/validatedForm/TextField'
import ListTableField from 'core/components/validatedForm/ListTableField'
import useParams from 'core/hooks/useParams'
import SubmitButton from 'core/components/SubmitButton'
import { propEq } from 'ramda'
import { emptyObj, pathStr } from 'utils/fp'
import { getGroupUsers } from './actions'
import { listUserTenants } from 'account/components/userManagement/tenants/new-actions'
import useListAction from 'core/hooks/useListAction'
import {
  listMngmGroups,
  updateMngmGroup,
  loadGroupRoleAssignments,
} from 'account/components/ssoManagement/groups/new-actions'
import { groupsSelector } from 'account/components/ssoManagement/groups/selectors'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { useSelector } from 'react-redux'
import { IGroupSelector } from 'account/components/ssoManagement/groups/model'

const FormWrapper: any = FormWrapperDefault // types on forward ref .js file dont work well.
const TenantRolesTableField: any = TenantRolesTableFieldDefault // types on forward ref .js file dont work well.

const useStyles = makeStyles((theme: Theme) => ({
  validatedFormContainer: {
    display: 'grid',
    gridGap: theme.spacing(2),
    margin: theme.spacing(3, 0, 3, 0),
  },
}))

const columns = [{ id: 'username', label: 'Name', disableSorting: true }]

const tenantRolesValidations = [requiredValidator.withMessage('Must select at least one tenant')]

const defaultParams = {
  id: '',
  name: '',
  description: '',
  roleAssignments: {},
  prevRoleAssignmentsArr: [],
  users: [],
  prevUserIds: [],
}

const EditUserGroupPage = () => {
  const { match, history } = useReactRouter()
  const classes = useStyles({})
  const groupId = match.params.id

  const { loading: loadingTenants } = useListAction(listTenants)
  const tenants = useSelector(tenantsSelector)

  const { loading: loadingGroups } = useListAction(listMngmGroups)
  const groups = useSelector(groupsSelector)

  const { loading: loadingUsers } = useListAction(listUsers)
  const users = useSelector(usersSelector)

  const { update, updating } = useUpdateAction(updateMngmGroup)

  const { params, getParamsUpdater, setParams } = useParams(defaultParams)
  const [roleAssignments, setRoleAssignments] = useState(null)
  const [existingGroupUsers, setExistingGroupUsers] = useState(null)
  const [initialValues, setInitialValues] = useState(null)
  const { reload: reloadUserTenants } = useListAction(listUserTenants)
  const group = useMemo(() => groups.find(propEq('id', groupId)) || (emptyObj as IGroupSelector), [
    groups,
    groupId,
  ])
  const mappedUsers = useMemo(() => {
    if (!users?.length || !existingGroupUsers) {
      return []
    }
    const existingGroupUserIds = existingGroupUsers.map((user) => user.id)
    return users.map((user) => ({
      ...user,
      // ascending sort existing users to the top
      belongsToGroup: existingGroupUserIds.includes(user.id) ? 1 : 2,
    }))
  }, [users, existingGroupUsers])

  const finishedInitialLoad = !(
    loadingGroups ||
    loadingUsers ||
    !roleAssignments ||
    !existingGroupUsers
  )

  useEffect(() => {
    const loadRoles = async () => {
      const roles = await loadGroupRoleAssignments(groupId)
      setRoleAssignments(roles)
    }
    const loadGroupUsers = async () => {
      const _users = await getGroupUsers(groupId)
      setExistingGroupUsers(_users)
    }

    loadRoles()
    loadGroupUsers()
  }, [groupId])

  useEffect(() => {
    if (finishedInitialLoad) {
      const existingGroupUserIds = existingGroupUsers.map((user) => user.id)
      const startingBody = {
        id: groupId,
        name: group.name,
        description: group.description,
        roleAssignments: roleAssignments?.reduce(
          (acc, roleAssignment) => ({
            ...acc,
            [pathStr('scope.project.id', roleAssignment)]: pathStr('role.id', roleAssignment),
          }),
          {},
        ),
        prevRoleAssignmentsArr: roleAssignments,
        users: mappedUsers.filter((user) => {
          return existingGroupUserIds.includes(user.id)
        }),
        prevUserIds: existingGroupUserIds,
      }
      setInitialValues(startingBody)
      setParams(startingBody)
    }
  }, [finishedInitialLoad])

  const submitForm = useCallback(
    async (values) => {
      // ValidatedForm only includes fields that exist so need to send initialValues
      const body = {
        ...initialValues,
        name: values.name,
        description: values.description,
        roleAssignments: values.roleAssignments,
        users: values.users,
      }
      await update(body)
      reloadUserTenants(true)
      history.push(routes.userManagement.root.path({ tab: 'groups' }))
    },
    [initialValues],
  )

  return (
    <>
      <DocumentMeta title="Edit Group" bodyClasses={['form-view']} />
      <FormWrapper
        title={`Edit Group ${group.name || ''}`}
        backUrl={routes.userManagement.root.path({ tab: 'groups' })}
        loading={!initialValues || updating}
        message={updating ? 'Submitting form...' : 'Loading Group...'}
        isUpdateForm
      >
        {initialValues?.id && (
          <ValidatedForm
            classes={{ root: classes.validatedFormContainer }}
            onSubmit={submitForm}
            title="Edit Group"
            elevated={false}
            initialValues={initialValues}
            formActions={<SubmitButton>Update Group</SubmitButton>}
          >
            <FormFieldCard title="Group Settings">
              <TextField
                id="name"
                label="Name"
                onChange={getParamsUpdater('name')}
                value={params.name}
                required
              />
              <TextField
                id="description"
                label="Description"
                onChange={getParamsUpdater('description')}
                value={params.description}
              />
            </FormFieldCard>
            <FormFieldCard title="Tenants & Roles">
              <Text variant="body2">
                Specify what Tenants this Group should have access to, and what role the users will
                be assigned in each Tenant.
              </Text>
              <Progress loading={loadingTenants} message={'Loading Tenants...'}>
                <TenantRolesTableField
                  validations={tenantRolesValidations}
                  id="roleAssignments"
                  tenants={tenants}
                  onChange={getParamsUpdater('roleAssignments')}
                  value={params.roleAssignments}
                />
              </Progress>
            </FormFieldCard>
            <FormFieldCard title="Users">
              <Text variant="body2">Specify which Users belong to this Group.</Text>
              <ListTableField
                id="users"
                data={mappedUsers}
                loading={loadingUsers}
                columns={columns}
                onChange={getParamsUpdater('users')}
                value={params.users}
                uniqueIdentifier="id"
                searchTargets={['username']}
                orderBy={'belongsToGroup'}
                multiSelection
              />
            </FormFieldCard>
          </ValidatedForm>
        )}
      </FormWrapper>
    </>
  )
}

export default EditUserGroupPage
