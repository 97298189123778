import React from 'react'
import DocumentMeta from 'core/components/DocumentMeta'
import useListAction from 'core/hooks/useListAction'
import { listMngmRoles } from './new-actions'
import { useSelector } from 'react-redux'
import { managementRolesSelector } from './selectors'
import { pick } from 'ramda'
import { createUsePrefParamsHook } from 'core/hooks/useParams'
import { listTablePrefs, TablePrefsParams } from 'app/constants'
import ListContainer from 'core/containers/ListContainer'
import { ArrayElement } from 'core/actions/Action'
import { GridViewColumn } from 'core/elements/grid/Grid'
import DataKeys from 'k8s/DataKeys'
import Text from 'core/elements/Text'

type ModelDataKey = DataKeys.ManagementRoles
type SelectorModel = ArrayElement<ReturnType<typeof managementRolesSelector>>

const usePrefParams = createUsePrefParamsHook<TablePrefsParams>('ManagementRoles', listTablePrefs)

const searchTargets = ['displayName']

const DescriptionCell = ({ value }) => (
  <Text variant="body2" component="p">
    {value}
  </Text>
)

const columns: GridViewColumn<SelectorModel>[] = [
  { key: 'displayName', label: 'Role', width: 'medium' },
  // Having width: medium on the one above causes the other column to take rest of space
  { key: 'description', label: 'Description', CellComponent: DescriptionCell },
]

export default function UserRolesListPage() {
  const { params, getParamsUpdater } = usePrefParams({})
  const { message, loading, reload } = useListAction(listMngmRoles)
  const data = useSelector(managementRolesSelector)

  // Using ListTable here still bc this table does not look good with Grid
  return (
    <>
      <DocumentMeta title="Roles" />
      <ListContainer<ModelDataKey, SelectorModel>
        label="User Roles"
        searchTargets={searchTargets}
        uniqueIdentifier="id"
        loading={loading}
        loadingMessage={message}
        onRefresh={reload}
        columns={columns}
        getParamsUpdater={getParamsUpdater}
        data={data}
        showBreadcrumbs={false}
        compact
        disablePagination
        {...pick(listTablePrefs, params)}
      />
    </>
  )
}
