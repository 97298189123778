import React from 'react'
import useReactRouter from 'use-react-router'
import { routes } from 'core/utils/routes'
import FormWrapperDefault from 'core/components/FormWrapper'
import DocumentMeta from 'core/components/DocumentMeta'
import ValidatedForm from 'core/components/validatedForm/ValidatedForm'
import { FormFieldCard } from 'core/components/validatedForm/FormFieldCard'
import Text from 'core/elements/Text'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import TenantRolesTableFieldDefault from '../users/TenantRolesTableField'
import { requiredValidator } from 'core/utils/fieldValidators'
import { listTenants } from '../../userManagement/tenants/new-actions'
import { useSelector } from 'react-redux'
import { tenantsSelector } from 'account/components/userManagement/tenants/selectors'
import Progress from 'core/components/progress/Progress'
import { listUsers } from '../users/new-actions'
import { usersSelector } from '../users/selectors'
import TextField from 'core/components/validatedForm/TextField'
import ListTableField from 'core/components/validatedForm/ListTableField'
import useParams from 'core/hooks/useParams'
import SubmitButton from 'core/components/SubmitButton'
import { listUserTenants } from 'account/components/userManagement/tenants/new-actions'
import useListAction from 'core/hooks/useListAction'
import { createMngmGroup } from 'account/components/ssoManagement/groups/new-actions'
import useUpdateAction from 'core/hooks/useUpdateAction'

const FormWrapper: any = FormWrapperDefault // types on forward ref .js file dont work well.
const TenantRolesTableField: any = TenantRolesTableFieldDefault // types on forward ref .js file dont work well.

const useStyles = makeStyles((theme: Theme) => ({
  validatedFormContainer: {
    display: 'grid',
    gridGap: theme.spacing(2),
  },
}))

const columns = [{ id: 'username', label: 'Name' }]

const tenantRolesValidations = [requiredValidator.withMessage('Must select at least one tenant')]

const defaultParams = { roleAssignments: {}, name: '', users: [] }

const AddUserGroupPage = () => {
  const { history } = useReactRouter()
  const classes = useStyles({})

  const { loading: loadingTenants } = useListAction(listTenants)
  const tenants = useSelector(tenantsSelector)

  const { loading: loadingUsers } = useListAction(listUsers)
  const users = useSelector(usersSelector)

  const { update, updating } = useUpdateAction(createMngmGroup)

  const { params, getParamsUpdater } = useParams(defaultParams)
  const { reload: reloadUserTenants } = useListAction(listUserTenants)

  const submitForm = async (values) => {
    await update({
      name: values.name,
      description: values.description,
      roleAssignments: values.roleAssignments,
      users: values.users,
    })
    reloadUserTenants(true)
    history.push(routes.userManagement.root.path({ tab: 'groups' }))
  }

  return (
    <>
      <DocumentMeta title="Add Group" bodyClasses={['form-view']} />
      <FormWrapper
        title="Add New Group"
        backUrl={routes.userManagement.root.path({ tab: 'groups' })}
        loading={updating}
      >
        <ValidatedForm
          classes={{ root: classes.validatedFormContainer }}
          onSubmit={submitForm}
          title="Add Group"
          elevated={false}
          formActions={<SubmitButton>Add Group</SubmitButton>}
        >
          <FormFieldCard title="Group Settings">
            <TextField id="name" label="Name" required />
            <TextField id="description" label="Description" />
          </FormFieldCard>
          <FormFieldCard title="Tenants & Roles">
            <Text variant="body2">
              Specify what Tenants this Group should have access to, and what role the users will be
              assigned in each Tenant.
            </Text>
            <Progress loading={loadingTenants} message={'Loading Tenants...'}>
              <TenantRolesTableField
                validations={tenantRolesValidations}
                id="roleAssignments"
                tenants={tenants}
                onChange={getParamsUpdater('roleAssignments')}
                value={params.roleAssignments}
              />
            </Progress>
          </FormFieldCard>
          <FormFieldCard title="Users">
            <Text variant="body2">Specify which Users belong to this Group.</Text>
            <ListTableField
              id="users"
              data={users}
              loading={loadingUsers}
              columns={columns}
              onChange={getParamsUpdater('users')}
              value={params.users}
              uniqueIdentifier="id"
              searchTargets={['username']}
              multiSelection
            />
          </FormFieldCard>
        </ValidatedForm>
      </FormWrapper>
    </>
  )
}

export default AddUserGroupPage
