import { pipe, pluck, join, pick } from 'ramda'
import { routes } from 'core/utils/routes'
import DocumentMeta from 'core/components/DocumentMeta'
import DataKeys from 'k8s/DataKeys'
import React, { useMemo } from 'react'
import { createUsePrefParamsHook } from 'core/hooks/useParams'
import { listTablePrefs, TablePrefsParams } from 'app/constants'
import useListAction from 'core/hooks/useListAction'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import { tenantsSelector } from './selectors'
import { listTenants, deleteTenant } from './new-actions'
import { GridViewColumn } from 'core/elements/grid/Grid'
import ListContainer from 'core/containers/ListContainer'
import { Tenant } from 'api-client/keystone.model'
import { listNamespaces } from 'k8s/components/namespaces/new-actions'
import { namespacesSelector } from 'k8s/components/namespaces/selectors'
import { allKey } from 'app/constants'

type ModelDataKey = DataKeys.ManagementTenants
type SelectorModel = Tenant

const isNotServiceTenant = (tenants) => !tenants.find((t) => t.name === 'service')

const usePrefParams = createUsePrefParamsHook<TablePrefsParams>('Tenants', listTablePrefs)

const searchTargets = ['name']

const columns: GridViewColumn<SelectorModel>[] = [
  { key: 'id', label: 'Tenant Uuid' },
  { key: 'name', label: 'Name' },
  { key: 'description', label: 'Description' },
  { key: 'clusters', label: 'Mapped Clusters' },
  {
    key: 'users',
    label: 'Users',
    display: false,
    formatFn: pipe(pluck('name'), join(', ')),
  },
]

export default function TenantsListPage() {
  const { params, getParamsUpdater } = usePrefParams({})
  const { message, loading, reload } = useListAction(listTenants)

  const data = useSelectorWithParams(tenantsSelector, params)

  // Todo: Fix issue where when hard refreshing on this page, namespace list action processes
  // clusterId: allKey before clusters are loaded
  useListAction(listNamespaces, {
    params: { clusterId: allKey },
  })
  const namespaces = useSelectorWithParams(namespacesSelector, { useGlobalParams: false })

  const dataWithClusters = useMemo(
    () =>
      data.map((tenant) => ({
        ...tenant,
        clusters: pluck(
          'clusterName',
          namespaces?.filter((namespace) => namespace?.metadata?.name === tenant.name),
        ),
      })),
    [data, namespaces],
  )

  return (
    <>
      <DocumentMeta title="Tenants" />
      <ListContainer<ModelDataKey, SelectorModel>
        dataKey={DataKeys.ManagementTenants}
        searchTargets={searchTargets}
        nameProp="name"
        uniqueIdentifier="id"
        loading={loading}
        loadingMessage={message}
        onRefresh={reload}
        data={dataWithClusters}
        columns={columns}
        addUrl={routes.userManagement.addTenant.path()}
        addText="Create a New Tenant"
        getParamsUpdater={getParamsUpdater}
        editUrl={(_, id) => routes.userManagement.editTenant.path({ id })}
        deleteCond={isNotServiceTenant}
        editCond={isNotServiceTenant}
        deleteAction={deleteTenant}
        showBreadcrumbs={false}
        {...pick(listTablePrefs, params)}
      />
    </>
  )
}
