import { uuidRegex } from 'app/constants'

export enum LoginMethodTypes {
  Local = 'local',
  SSO = 'sso',
}

export const isSystemUser = ({ username, name }: { username?: string; name: string }) => {
  const fieldToUse = username ?? name
  const isUuid = uuidRegex.test(fieldToUse)
  return isUuid || fieldToUse === 'kplane-clustmgr'
}
