// Libs
import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'

// Helpers
import { SuspendResumeMessages } from './helpers'
import Text from 'core/elements/Text'
import { HeaderTitlePortal } from 'core/elements/header/portals'
import Tooltip from 'core/elements/tooltip'
import { bottomMiddle } from 'core/elements/menu/defaults'

interface Props {
  taskState: string
}

export default function Summary({ taskState }: Props) {
  const classes = useStyles()
  const summaryMessage = SuspendResumeMessages.getSummaryMessage(taskState)
  const detailMessage = SuspendResumeMessages.getDetailMessage(taskState)
  return (
    <HeaderTitlePortal>
      <Tooltip
        message={detailMessage}
        align={bottomMiddle.align}
        offset={bottomMiddle.offset}
        origin="top center"
        className={classes.tooltip}
      >
        <Text variant="subtitle1">{summaryMessage}</Text>
      </Tooltip>
    </HeaderTitlePortal>
  )
}

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  tooltip: {
    width: 'auto',
  },
}))
