import React from 'react'
import Text from 'core/elements/Text'
import Button from 'core/elements/button'
import ExternalLink from 'core/components/ExternalLink'
import { CustomerTiers, upgradeLinks } from 'app/constants'
import { trackEvent } from 'utils/tracking'
import Modal from 'core/elements/modal'

interface Props {
  onClose: () => void
  feature: string
}

const AccountUpgradeDialog = ({ onClose, feature }: Props) => {
  return (
    <Modal
      panel="dialog"
      title="Upgrade your account"
      open
      onClose={onClose}
      footer={
        <>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <ExternalLink url={upgradeLinks[CustomerTiers.Growth]}>
            <Button
              onClick={() =>
                trackEvent(`FT User - Clicked Upgrade Now - ${feature}`, {
                  duDomain: window.location.origin,
                })
              }
            >
              Upgrade Now
            </Button>
          </ExternalLink>
        </>
      }
    >
      <Text variant="body2">
        <b>{feature}</b> is only available to{' '}
        <ExternalLink url={upgradeLinks[CustomerTiers.Growth]}>enterprise users</ExternalLink> of
        Platform9.
      </Text>
      <Text variant="body2">
        Upgrade your account now to access this feature and other great features including tailored
        support with a designated account and customer success team.
      </Text>
    </Modal>
  )
}

export default AccountUpgradeDialog
