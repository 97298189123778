import React, { useCallback, useMemo } from 'react'
import { makeStyles } from '@material-ui/styles'

import Text from 'core/elements/Text'
import Radio from 'core/elements/input/Radio'

import Theme from 'core/themes/model'
import { withCustomTheme, useCustomTheme } from 'core/themes/ThemeManager'
import clsx from 'clsx'
import { useSelector } from 'react-redux'
import { dissoc, prop } from 'ramda'
import { ThemeReducer } from 'app/plugins/account/components/theme/model'
import { ThemeLabels, themesByKey } from 'core/themes/serenity'

type IThemeOptions = {
  id: 'default' | 'light' | 'dark' | 'ultra-dark'
  label: string
}

const ThemeChoiceCard = () => {
  const [currentTheme, setCustomTheme] = useCustomTheme()
  const themeStore = useSelector(prop<string, ThemeReducer>('theme'))
  const { global: globalTheme } = themeStore
  const hasGlobalTheme = !!(
    globalTheme?.headerColor ||
    globalTheme?.sidenavColor ||
    globalTheme?.logoUrl
  )
  const themeKeyToHide = hasGlobalTheme ? 'default' : 'custom'
  const themeOptions = useMemo(() => dissoc(themeKeyToHide, themesByKey), [themeKeyToHide])
  const classes = useStyles({})
  const handleChange = useCallback((key) => {
    setCustomTheme(themesByKey[key])
  }, [])

  return (
    <>
      <Text variant="body2">Customize the appearance of Platform9.</Text>
      <div className={classes.themeChoices}>
        {Object.keys(themeOptions).map((themeKey) => (
          <ThemeOption
            label={ThemeLabels[themeKey]}
            disabled={hasGlobalTheme}
            key={themeKey}
            id={themeKey}
            checked={
              currentTheme.palette.themeKey === themeKey ||
              (currentTheme.palette.themeKey === 'custom' && themeKey === 'default')
            }
            onChange={handleChange}
          />
        ))}
      </div>
    </>
  )
}
export default withCustomTheme(ThemeChoiceCard)

function ThemeOption({ id, label, checked, onChange, disabled }) {
  const classes = useStyles({ id, disabled })
  const handleClick = useCallback(() => {
    if (disabled) {
      return
    }
    onChange(id)
  }, [disabled])
  return (
    <div className={clsx(classes.themeOption, classes.disabled)} onClick={handleClick}>
      <figure className={classes.themeCard} title={label}>
        <header />
        <aside />
        <section />
      </figure>
      <Radio checked={checked} label={label} onChange={handleClick} disabled={disabled} />
    </div>
  )
}

const useStyles = makeStyles<Theme, { id?: IThemeOptions['id']; disabled?: boolean }>((theme) => ({
  themeChoices: {
    gridTemplateColumns: 'repeat(2, 194px)',
    gap: 16,
    justifyContent: 'space-evenly',
    display: 'grid',
    marginTop: 24,
  },
  themeOption: {
    display: 'grid',
    gridAutoFlow: 'row',
    alignItems: 'center',
    justifyItems: 'center',
    justifyContent: 'center',
    gap: 16,
  },
  disabled: {
    cursor: ({ disabled }) => (disabled ? 'not-allowed' : ''),
  },
  themeCard: {
    cursor: 'pointer',
    borderRadius: 4,
    backgroundColor: ({ id }) => themesByKey?.[id]?.components?.frame?.background,
    width: 192,
    height: 108,
    border: ({ id }) => `1px solid ${themesByKey?.[id]?.components?.sidebar?.border}`,
    overflow: 'hidden',
    display: 'grid',
    gridTemplateAreas: '"theme-card-aside theme-card-header" "theme-card-aside theme-card-section"',
    gridTemplateColumns: '32px 1fr',
    gap: 8,
    margin: 0,

    '& > aside': {
      gridArea: 'theme-card-aside',
      backgroundColor: ({ id }) => themesByKey?.[id]?.components?.sidebar?.background,
      borderRight: ({ id }) => `1px solid ${themesByKey?.[id]?.components?.sidebar?.border}`,
    },
    '& > header': {
      gridArea: 'theme-card-header',
      backgroundColor: ({ id }) => themesByKey?.[id]?.components?.card?.background,
      margin: '12px 12px 0px 12px',
    },
    '& > section': {
      gridArea: 'theme-card-section',
      backgroundColor: ({ id }) => themesByKey?.[id]?.components?.card?.background,
      margin: '0px 12px 12px 12px',
    },
  },
}))
