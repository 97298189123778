import React, { useCallback } from 'react'
import { pathStr } from 'utils/fp'
import SubmitButton from 'core/components/buttons/SubmitButton'
import CancelButton from 'core/components/buttons/CancelButton'
import useListAction from 'core/hooks/useListAction'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { listUserRoleAssignments, updateUser } from './new-actions'
import { userRolesSelector } from './selectors'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import Modal from 'core/elements/modal'
import Text from 'core/elements/Text'

const EnableDisableUserDialog = ({ rows: [user], onClose }) => {
  const action = user.enabled ? 'Disable' : 'Enable'
  const { update, updating } = useUpdateAction(updateUser)

  const { loading } = useListAction(listUserRoleAssignments, { params: { userId: user.id } })
  const roleAssignments = useSelectorWithParams(userRolesSelector, { userId: user.id })

  const handleSubmit = useCallback(async () => {
    const userRoleAssignments = roleAssignments.reduce(
      (acc, roleAssignment) => ({
        ...acc,
        [pathStr('scope.project.id', roleAssignment)]: pathStr('role.id', roleAssignment),
      }),
      {},
    )

    await update({
      id: user.id,
      enabled: !user.enabled,
      roleAssignments: userRoleAssignments,
    })
    onClose(true)
  }, [user, roleAssignments])

  return (
    <Modal
      open
      panel="dialog"
      onClose={onClose}
      title={`${action} User`}
      footer={
        <>
          <CancelButton onClick={onClose} disabled={loading || updating} />
          <SubmitButton loading={loading || updating} onClick={handleSubmit} />
        </>
      }
    >
      <Text variant="body2">
        {action} user {user.username}?
      </Text>
    </Modal>
  )
}

export default EnableDisableUserDialog
