import React, { useCallback, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import DocumentMeta from 'core/components/DocumentMeta'
import ValidatedForm from 'core/components/validatedForm/ValidatedForm'
import SubmitButton from 'core/components/SubmitButton'
import useParams from 'core/hooks/useParams'
import Progress from 'core/components/progress/Progress'
import { FormFieldCard } from 'core/components/validatedForm/FormFieldCard'
import Text from 'core/elements/Text'
import ThemeToggle from './ThemeToggle'
import AccountUpgradeDialog from './AccountUpgradeDialog'
import { CustomerTiers, themeEnabledTiers, dashboardUrl } from 'app/constants'
import { pathOr, prop } from 'ramda'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'app/store'
import { SessionState, sessionStoreKey } from 'core/session/sessionReducers'
import TextField from 'core/components/validatedForm/TextField'
import clsx from 'clsx'
import { hexToRgb, rgbToHex } from 'core/utils/colorHelpers'
import { deleteThemeConfig, getThemeConfig, updateThemeConfig, updateSessionTheme } from './actions'
import ThemeEnabledDialog from './ThemeEnabledDialog'
import { ThemeConfig } from './model'
import { themeActions } from 'core/session/themeReducers'
import { preferencesActions } from 'core/session/preferencesReducers'
import { colorHexValidator, customValidator } from 'core/utils/fieldValidators'
import { trackEvent } from 'utils/tracking'
import { HeaderTitlePortal } from 'core/elements/header/portals'
import PassiveHeaderLink from 'core/components/passive-header-link'
import ImageDrop from 'core/elements/ImageDrop'
import { notificationActions, NotificationType } from 'core/notifications/notificationReducers'

const useStyles = makeStyles((theme: Theme) => ({
  validatedFormContainer: {
    display: 'grid',
    gridGap: theme.spacing(2),
  },
  cardContent: {
    margin: theme.spacing(0, 2),
  },
  spaceBelow: {
    marginBottom: theme.spacing(1.5),
  },
  logoContainer: {
    marginTop: theme.spacing(3),
    display: 'grid',
    gridTemplateColumns: '350px auto',
    columnGap: theme.spacing(5),
  },
  logoBackground: {
    border: `1px solid ${theme.palette.grey[300]}`,
    height: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  imageContainer: {
    width: 250,
    height: 50,
    background: 'transparent',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logoImage: {
    maxHeight: 50,
    maxWidth: 250,
  },
  tooltip: {
    marginTop: theme.spacing(2),
  },
  colorsContainer: {
    display: 'grid',
    marginTop: theme.spacing(4),
    gridTemplateColumns: '248px 248px',
    columnGap: 32,
  },
  colorDisplay: {
    display: 'inline-block',
    height: 48,
    width: 248,
    border: `1px solid ${theme.palette.grey[200]}`,
    marginTop: 8,
    borderRadius: 4,
  },
  backgroundColor: {
    background: theme.palette.grey[100],
  },
  navigationColor: {
    background: theme.palette.grey[800],
  },
  rgb: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    columnGap: theme.spacing(1),
  },
  rgbTextField: {
    width: 'initial !important',
    '& .MuiOutlinedInput-root': {
      minWidth: 'initial',
    },
  },
  fieldSection: {
    borderTop: `1px solid ${theme.components.card.border}`,
    marginTop: 24,
    paddingTop: 16,
  },
  logoFields: {
    marginTop: 16,
    display: 'grid',
    gridTemplateColumns: '335px auto',
    gridGap: 32,
  },
  sizeInfo: {
    marginTop: 24,
    color: theme.components.card.passiveText,
  },
}))

type State = {
  enableTheme: boolean
  themeIsEnabled: boolean
  logoUrl: string
  logoSrc: string
  logoProps: any
  backgroundHex: string
  navigationHex: string
  backgroundR: string
  backgroundG: string
  backgroundB: string
  navigationR: string
  navigationG: string
  navigationB: string
}

const fileSizeValidator = customValidator((file) => {
  if (!file || !file.size) {
    return true
  }
  return file?.size <= 40000
}, 'File size must be 40KB or less')

const sizeInKB = (bytes) => Math.round((bytes / 1000) * 10) / 10 // Round to nearest tenth

const updateTheme = (data, setLoading, setSavedDialogOpened, updateParams, dispatch) => {
  const sendRequest = async () => {
    const body = {
      headerColor: data.backgroundHex,
      sidenavColor: data.navigationHex,
      logoUrl: data.logoUrl,
      logoSrc: data.logoSrc,
      logoFileName: data.logoProps.name,
    }
    setLoading(true)
    try {
      await updateThemeConfig(body)
      updateSessionTheme(dispatch, data)
      updateParams({ themeIsEnabled: true })
      // Show user a dialog saying theme configuration successful
      setSavedDialogOpened(true)
    } catch (err) {
      const customErrorMessage =
        'Theme failed to update. If uploading a custom logo, please make sure that the logo size is less than 65KB'
      dispatch(
        notificationActions.registerNotification({
          title: `Error Updating Theme`,
          message: customErrorMessage,
          data: { message: customErrorMessage },
          type: NotificationType.error,
        }),
      )
    } finally {
      setLoading(false)
    }
  }

  sendRequest()
}

const CustomThemePage = () => {
  const classes = useStyles({})
  const { params, updateParams, getParamsUpdater } = useParams<State>({
    enableTheme: false,
    themeIsEnabled: false,
    logoUrl: '',
    logoSrc: '',
    logoProps: { name: '', size: undefined },
    backgroundHex: '',
    navigationHex: '',
    backgroundR: '',
    backgroundG: '',
    backgroundB: '',
    navigationR: '',
    navigationG: '',
    navigationB: '',
  })
  const [loading, setLoading] = useState(true)
  const [dialogOpened, setDialogOpened] = useState(false)
  const [savedDialogOpened, setSavedDialogOpened] = useState(false)
  const session = useSelector<RootState, SessionState>(prop(sessionStoreKey))
  const { features } = session
  const dispatch = useDispatch()

  useEffect(() => {
    const getSettings = async () => {
      try {
        const {
          headerColor: backgroundHex,
          sidenavColor: navigationHex,
          logoUrl,
          logoSrc,
          logoFileName,
        }: ThemeConfig = await getThemeConfig()
        updateParams({
          backgroundHex,
          navigationHex,
          logoUrl,
          logoSrc,
          logoProps: {
            logoFileName,
          },
          enableTheme: true,
          themeIsEnabled: true,
        })
      } catch (err) {
        console.log(err, 'error')
        updateParams({ themeIsEnabled: false })
      }
      setLoading(false)
    }
    getSettings()
  }, [])

  useEffect(() => {
    const backgroundRgb = hexToRgb(params.backgroundHex)
    if (backgroundRgb) {
      updateParams({
        backgroundR: `${backgroundRgb.r}`,
        backgroundG: `${backgroundRgb.g}`,
        backgroundB: `${backgroundRgb.b}`,
      })
    }
  }, [params.backgroundHex])

  useEffect(() => {
    const navigationRgb = hexToRgb(params.navigationHex)
    if (navigationRgb) {
      updateParams({
        navigationR: `${navigationRgb.r}`,
        navigationG: `${navigationRgb.g}`,
        navigationB: `${navigationRgb.b}`,
      })
    }
  }, [params.navigationHex])

  useEffect(() => {
    if (params.backgroundR && params.backgroundG && params.backgroundB) {
      updateParams({
        backgroundHex: rgbToHex(params.backgroundR, params.backgroundG, params.backgroundB),
      })
    }
  }, [params.backgroundR, params.backgroundG, params.backgroundB])

  useEffect(() => {
    if (params.navigationR && params.navigationG && params.navigationB) {
      updateParams({
        navigationHex: rgbToHex(params.navigationR, params.navigationG, params.navigationB),
      })
    }
  }, [params.navigationR, params.navigationG, params.navigationB])

  const toggleTheme = useCallback(async () => {
    if (params.enableTheme && params.themeIsEnabled) {
      await deleteThemeConfig()
      dispatch(themeActions.clearTheme({}))
      dispatch(
        preferencesActions.updateLogo({
          logoUrl: '',
          logoSrc: '',
          logoFileName: '',
        }),
      )
      updateParams({ enableTheme: false, themeIsEnabled: false })
      return
    }
    if (
      !params.enableTheme &&
      !themeEnabledTiers.includes(pathOr(CustomerTiers.Freedom, ['customer_tier'], features))
    ) {
      // If custom theme is not available for customer tier
      setDialogOpened(true)
      trackEvent('FT User - Tried to Enable Custom Theme', {
        duDomain: window.location.origin,
      })
      return
    }
    updateParams({ enableTheme: !params.enableTheme })
  }, [params, updateParams])

  return (
    <div>
      <DocumentMeta title="Custom Theme" />
      <HeaderTitlePortal>
        <PassiveHeaderLink icon="chevron-left" text="Back to Dashboard" url={dashboardUrl} />
      </HeaderTitlePortal>
      {dialogOpened && (
        <AccountUpgradeDialog feature="Custom Theme" onClose={() => setDialogOpened(false)} />
      )}
      <Progress loading={loading}>
        <ValidatedForm
          classes={{ root: classes.validatedFormContainer }}
          elevated={false}
          formActions={<>{params.enableTheme && <SubmitButton>Save</SubmitButton>}</>}
          onSubmit={() =>
            updateTheme(params, setLoading, setSavedDialogOpened, updateParams, dispatch)
          }
        >
          <FormFieldCard>
            <div className={classes.cardContent}>
              <ThemeToggle
                themeIsEnabled={params.themeIsEnabled}
                checked={params.enableTheme}
                onClick={toggleTheme}
              />
              <Text variant="body2" className={classes.spaceBelow}>
                Add your own logo & colors to create a custom theming and branding.
              </Text>
            </div>
            {params.enableTheme && (
              <>
                <div className={classes.fieldSection}>
                  <div className={classes.cardContent}>
                    <Text variant="subtitle2">Colors</Text>
                    <div className={classes.colorsContainer}>
                      <div>
                        <Text variant="caption1">Background</Text>
                        <div
                          className={clsx(classes.colorDisplay, classes.backgroundColor)}
                          style={{
                            background: params.backgroundHex.length === 7 && params.backgroundHex,
                          }}
                        />
                        <TextField
                          id="backgroundHex"
                          label="Hex Value"
                          onChange={getParamsUpdater('backgroundHex')}
                          value={params.backgroundHex}
                          placeholder="#f5f5f9"
                          maxLength={7}
                          validations={[colorHexValidator]}
                        />
                        <div className={classes.rgb}>
                          <TextField
                            id="backgroundR"
                            label="R"
                            onChange={getParamsUpdater('backgroundR')}
                            value={params.backgroundR}
                            placeholder="13"
                            className={classes.rgbTextField}
                            type="number"
                            min={0}
                            max={255}
                          />
                          <TextField
                            id="backgroundG"
                            label="G"
                            onChange={getParamsUpdater('backgroundG')}
                            value={params.backgroundG}
                            placeholder="13"
                            className={classes.rgbTextField}
                            type="number"
                            min={0}
                            max={255}
                          />
                          <TextField
                            id="backgroundB"
                            label="B"
                            onChange={getParamsUpdater('backgroundB')}
                            value={params.backgroundB}
                            placeholder="40"
                            className={classes.rgbTextField}
                            type="number"
                            min={0}
                            max={255}
                          />
                        </div>
                      </div>
                      <div>
                        <Text variant="caption1">Navigation</Text>
                        <div
                          className={clsx(classes.colorDisplay, classes.navigationColor)}
                          style={{
                            background: params.navigationHex.length === 7 && params.navigationHex,
                          }}
                        />
                        <TextField
                          id="navigationHex"
                          label="Hex Value"
                          onChange={getParamsUpdater('navigationHex')}
                          value={params.navigationHex}
                          placeholder="#25253f"
                          maxLength={7}
                          validations={[colorHexValidator]}
                        />
                        <div className={classes.rgb}>
                          <TextField
                            id="navigationR"
                            label="R"
                            onChange={getParamsUpdater('navigationR')}
                            value={params.navigationR}
                            placeholder="37"
                            className={classes.rgbTextField}
                            type="number"
                            min={0}
                            max={255}
                          />
                          <TextField
                            id="navigationG"
                            label="G"
                            onChange={getParamsUpdater('navigationG')}
                            value={params.navigationG}
                            placeholder="37"
                            className={classes.rgbTextField}
                            type="number"
                            min={0}
                            max={255}
                          />
                          <TextField
                            id="navigationB"
                            label="B"
                            onChange={getParamsUpdater('navigationB')}
                            value={params.navigationB}
                            placeholder="63"
                            className={classes.rgbTextField}
                            type="number"
                            min={0}
                            max={255}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={classes.fieldSection}>
                  <div className={classes.cardContent}>
                    <Text variant="subtitle2">Logo</Text>
                    <Text variant="body2">Upload your logo.</Text>
                    <Text variant="body2" className={classes.sizeInfo}>
                      Uploaded logo image file must be 40KB or less. Logo size will be reduced to
                      44x44 pixels.
                    </Text>
                    {params?.logoProps?.size && (
                      <Text variant="body2">
                        File size: <b>{sizeInKB(params.logoProps.size)}KB</b>
                      </Text>
                    )}
                    <div className={classes.logoFields}>
                      <ImageDrop
                        id="logoProps"
                        onChange={getParamsUpdater('logoProps')}
                        imageUpdater={getParamsUpdater('logoSrc')}
                        value={params.logoProps}
                        imageData={params.logoSrc}
                        validations={[fileSizeValidator]}
                      />
                      <TextField
                        id="logoUrl"
                        label="Logo Image URL"
                        onChange={getParamsUpdater('logoUrl')}
                        value={params.logoUrl}
                        info="Provide a link to the desired image for your logo."
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
          </FormFieldCard>
        </ValidatedForm>
      </Progress>
      {savedDialogOpened && <ThemeEnabledDialog onClose={() => setSavedDialogOpened(false)} />}
    </div>
  )
}

export default CustomThemePage
