import { tenantsSelector } from 'account/components/userManagement/tenants/selectors'
import { createUser } from 'account/components/userManagement/users/new-actions'
import TenantRolesTableField from 'account/components/userManagement/users/TenantRolesTableField'
import UserPasswordField from 'account/components/userManagement/users/UserPasswordField'
import FormWrapper from 'core/components/FormWrapper'
import Progress from 'core/components/progress/Progress'
import RadioFields, { Orientation } from 'core/components/validatedForm/radio-fields'
import TextField from 'core/components/validatedForm/TextField'
import ValidatedForm from 'core/components/validatedForm/ValidatedForm'
import Wizard from 'core/components/wizard/Wizard'
import WizardStep from 'core/components/wizard/WizardStep'
import useListAction from 'core/hooks/useListAction'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { emailValidator, requiredValidator } from 'core/utils/fieldValidators'
import { routes } from 'core/utils/routes'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import useReactRouter from 'use-react-router'
import { listTenants } from '../../userManagement/tenants/new-actions'

const listUrl = routes.userManagement.root.path()

const wizardMetaFormattedNames = {
  username: 'Email',
  displayname: 'Display Name',
  roleAssignments: 'Role Assignments',
}

const wizardMetaCalloutFields = Object.keys(wizardMetaFormattedNames)

const initialContext = {
  username: '',
  displayname: '',
  password: '',
  roleAssignments: {},
}
const tenantRolesValidations = [requiredValidator.withMessage('Must select at least one tenant')]

export const accountActivationRadioOptions = [
  {
    value: 'activationByEmail',
    label: 'Send activation email to the user.',
    info:
      ' Instructions to create a new password and to activate account will be sent to the email provided.',
  },
  {
    value: 'createPassword',
    label: 'Set password for new user now.',
    info: 'Create password for the new user now and activate the account immediately.',
  },
]

const AddUserPage = () => {
  const { history } = useReactRouter()
  const { update, updating, error: errorCreatingUser } = useUpdateAction(createUser)

  const submitForm = async (params) => {
    const { success } = await update(params)
    if (success) history.push(listUrl)
  }

  const { loading: loadingTenants } = useListAction(listTenants)
  const tenants = useSelector(tenantsSelector)

  const [activationType, setActivationType] = useState('createPassword')

  return (
    <FormWrapper title="New User" loading={updating} backUrl={listUrl}>
      <Wizard onComplete={submitForm} context={initialContext} error={errorCreatingUser}>
        {({ wizardContext, setWizardContext, onNext }) => (
          <>
            <WizardStep
              stepId="basic"
              label="Basic Info"
              summaryTitle="New User"
              summaryKeyOverrides={wizardMetaFormattedNames}
              summaryCalloutFields={wizardMetaCalloutFields}
            >
              <ValidatedForm
                title="Basic Info"
                initialValues={wizardContext}
                onSubmit={setWizardContext}
                triggerSubmit={onNext}
              >
                {({ values }) => (
                  <>
                    <TextField
                      id="username"
                      label="Email"
                      validations={[emailValidator]}
                      required
                    />
                    <TextField id="displayname" label="Display Name" />
                    <RadioFields
                      id="activationType"
                      orientation={Orientation.Row}
                      title="Activate User Account"
                      options={accountActivationRadioOptions}
                      value={activationType}
                      onChange={(type) => setActivationType(type)}
                    />
                    {activationType === 'createPassword' && (
                      <UserPasswordField value={values.password} />
                    )}
                  </>
                )}
              </ValidatedForm>
            </WizardStep>
            <WizardStep stepId="tenants" label="Tenants and Roles">
              <ValidatedForm
                title="Tenants and Roles"
                fullWidth
                initialValues={wizardContext}
                onSubmit={setWizardContext}
                triggerSubmit={onNext}
              >
                <Progress
                  renderContentOnMount={!loadingTenants}
                  loading={loadingTenants}
                  message={'Loading Tenants...'}
                >
                  <TenantRolesTableField
                    validations={tenantRolesValidations}
                    id="roleAssignments"
                    tenants={tenants}
                  />
                </Progress>
              </ValidatedForm>
            </WizardStep>
          </>
        )}
      </Wizard>
    </FormWrapper>
  )
}

export default AddUserPage
