import React, { useCallback, useEffect, useState } from 'react'
import { pick } from 'ramda'
import DataKeys from 'k8s/DataKeys'
import useListAction from 'core/hooks/useListAction'
import { useSelector } from 'react-redux'
import {
  listMngmGroups,
  deleteMngmGroup,
} from 'account/components/ssoManagement/groups/new-actions'
import { groupsSelector } from 'account/components/ssoManagement/groups/selectors'
import { routes } from 'core/utils/routes'
import { createUsePrefParamsHook } from 'core/hooks/useParams'
import { listTablePrefs, TablePrefsParams } from 'app/constants'
import { ArrayElement } from 'core/actions/Action'
import { GridViewColumn } from 'core/elements/grid/Grid'
import DocumentMeta from 'core/components/DocumentMeta'
import ListContainer from 'core/containers/ListContainer'
import { loadSsoConfig } from '../sso/actions'

type ModelDataKey = DataKeys.ManagementGroups
type SelectorModel = ArrayElement<ReturnType<typeof groupsSelector>>

const usePrefParams = createUsePrefParamsHook<TablePrefsParams>('Groups', listTablePrefs)
const searchTargets = ['name', 'description', 'samlAttributesString']

const columns: GridViewColumn<SelectorModel>[] = [
  { key: 'id', label: 'OpenStack ID', display: false },
  { key: 'name', label: 'Group Name' },
  { key: 'description', label: 'Description' },
  { key: 'samlAttributesString', label: 'SAML Attributes Mapped' },
]

export default function GroupsListPage() {
  const { params, getParamsUpdater } = usePrefParams({})
  const { message, loading, reload } = useListAction(listMngmGroups)
  const [ssoEnabled, setSsoEnabled] = useState(false)
  const [loadingSso, setLoadingSso] = useState(true)
  const data = useSelector(groupsSelector)

  const loadingSomething = loading || loadingSso

  useEffect(() => {
    const getSettings = async () => {
      try {
        await loadSsoConfig()
        setSsoEnabled(true)
      } catch (err) {
        setSsoEnabled(false)
      }
      setLoadingSso(false)
    }
    getSettings()
  }, [])

  return (
    <>
      <DocumentMeta title="Groups" />
      <ListContainer<ModelDataKey, SelectorModel>
        dataKey={DataKeys.ManagementGroups}
        searchTargets={searchTargets}
        uniqueIdentifier="id"
        loading={loadingSomething}
        loadingMessage={message}
        onRefresh={reload}
        columns={columns}
        data={data}
        getParamsUpdater={getParamsUpdater}
        addUrl={routes.sso.addGroup.path()}
        addText="New Group"
        addCond={() => ssoEnabled}
        editUrl={(_, id) => routes.sso.editGroup.path({ id })}
        deleteAction={deleteMngmGroup}
        showBreadcrumbs={false}
        {...pick(listTablePrefs, params)}
      />
    </>
  )
}
