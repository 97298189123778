import { createSelector } from '@reduxjs/toolkit'
import { pipe, pluck } from 'ramda'
import getDataSelector from 'core/utils/getDataSelector'
import DataKeys from 'k8s/DataKeys'
import { Tenant } from 'api-client/keystone.model'
import createSorter, { SortConfig } from 'core/helpers/createSorter'
import { selectParamsFromProps, createSharedSelector } from 'core/utils/selectorHelpers'

export const filterOutDomains = (tenant) => !!tenant.domain_id

export const reservedTenantNames = ['admin', 'services', 'Default', 'heat']
export const filterValidTenants = (tenant) => !reservedTenantNames.includes(tenant.name)

export const allTenantsSelector = createSharedSelector(
  getDataSelector<DataKeys.ManagementTenants>(DataKeys.ManagementTenants),
  (allTenantsAllUsers) => {
    return allTenantsAllUsers.filter(filterOutDomains).map((tenant) => ({
      ...tenant,
      users: tenant?.users?.filter((user) => user.username !== 'admin@platform9.net'),
    }))
  },
)

export const tenantsSelector = createSharedSelector(allTenantsSelector, (allTenantsAllUsers) => {
  return allTenantsAllUsers.filter(filterValidTenants)
})

export const makeFilteredTenantsSelector = (
  defaultParams = {
    orderBy: 'created_at',
    orderDirection: 'desc',
  } as SortConfig,
) => {
  const selectParams = selectParamsFromProps(defaultParams)
  return createSelector(tenantsSelector, selectParams, (tenants, params) => {
    const { orderBy, orderDirection } = params
    return pipe<Tenant[], Tenant[]>(createSorter({ orderBy, orderDirection }))(tenants)
  })
}

export const userTenantsSelector = getDataSelector<DataKeys.UserTenants>(DataKeys.UserTenants)

export const tenantRolesSelector = getDataSelector<DataKeys.ManagementTenantsRoleAssignments>(
  DataKeys.ManagementTenantsRoleAssignments,
  ['tenantId'],
)
