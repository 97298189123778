import Bugsnag from 'utils/bugsnag'
import ApiClient from 'api-client/ApiClient'
import DataKeys, { entityNamesByKey } from 'k8s/DataKeys'
import { always, isNil, keys, reject } from 'ramda'
import { tryCatchAsync } from 'utils/async'
import { emptyArr, pathStr } from 'utils/fp'
import { trackEvent } from 'utils/tracking'
import ActionsSet from 'core/actions/ActionsSet'
import ListAction from 'core/actions/ListAction'
import UpdateAction from 'core/actions/UpdateAction'
import CreateAction from 'core/actions/CreateAction'
import DeleteAction from 'core/actions/DeleteAction'
import store from 'app/store'
import { usersSelector } from 'account/components/userManagement/users/selectors'
import getDataSelector from 'core/utils/getDataSelector'

const { keystone } = ApiClient.getInstance()

const tenantRolesSelector = getDataSelector(DataKeys.ManagementTenantsRoleAssignments, 'tenantId')

// This is for the one-time get during session setup, needs to return the projects
// directly in a function call, which ActionSet actions cannot do
export const loadUserTenants = async () => {
  return keystone.getProjectsAuth()
}

export const userTenantActions = ActionsSet.make<DataKeys.UserTenants>({
  uniqueIdentifier: 'id',
  entityName: entityNamesByKey.UserTenants,
  cacheKey: DataKeys.UserTenants,
})

export const listUserTenants = userTenantActions.add(
  new ListAction<DataKeys.UserTenants>(async () => {
    Bugsnag.leaveBreadcrumb('Attempting to load user tenants')
    return keystone.getProjectsAuth()
  }),
)

export const mngmTenantRoleAssignmentsActions = ActionsSet.make<
  DataKeys.ManagementTenantsRoleAssignments
>({
  uniqueIdentifier: ['user.id', 'role.id', 'scope.project.id'],
  indexBy: 'tenantId',
  entityName: entityNamesByKey.ManagementTenantsRoleAssignments,
  cacheKey: DataKeys.ManagementTenantsRoleAssignments,
})

export const listTenantRoleAssignments = mngmTenantRoleAssignmentsActions.add(
  new ListAction<DataKeys.ManagementTenantsRoleAssignments, { tenantId: string }>(
    async ({ tenantId }) => {
      Bugsnag.leaveBreadcrumb('Attempting to get tenant role assignments')
      const result = await keystone.getTenantRoleAssignments(tenantId)
      return result || emptyArr
    },
  ),
)

export const tenantActions = ActionsSet.make<DataKeys.ManagementTenants>({
  uniqueIdentifier: 'id',
  entityName: entityNamesByKey.ManagementTenants,
  cacheKey: DataKeys.ManagementTenants,
})

export const listTenants = tenantActions.add(
  new ListAction<DataKeys.ManagementTenants>(async () => {
    Bugsnag.leaveBreadcrumb('Attempting to get all tenants and all users')
    return keystone.getAllTenantsAllUsers()
  }),
)

export const createTenant = tenantActions.add(
  new CreateAction<
    DataKeys.ManagementTenants,
    { name: string; description: string; roleAssignments: string }
  >(async ({ name, description, roleAssignments }) => {
    Bugsnag.leaveBreadcrumb('Attempting to create new tenant', { name, description })
    const createdTenant = await keystone.createProject({
      name,
      description,
      enabled: true,
      domain_id: 'default',
      is_domain: false,
    })
    trackEvent('Create New Tenant', { name, description })
    const users = usersSelector(store.getState(), {})

    await tryCatchAsync(
      () =>
        Promise.all(
          Object.entries(roleAssignments).map(([userId, roleId]) =>
            keystone.addUserRole({ tenantId: createdTenant.id, userId, roleId }),
          ),
        ),
      (err) => {
        console.warn((err || {}).message)
        return emptyArr
      },
    )(null)
    const userKeys = Object.keys(roleAssignments)
    return {
      ...createdTenant,
      users: users.filter((user) => userKeys.includes(user.id)),
    }
  }),
)

export const updateTenant = tenantActions.add(
  new UpdateAction<
    DataKeys.ManagementTenants,
    { id: string; name: string; description: string; roleAssignments: unknown[] },
    any
  >(async ({ id: tenantId, name, description, roleAssignments }) => {
    Bugsnag.leaveBreadcrumb('Attempting to update tenant', { tenantId, name, description })

    const prevRoleAssignmentsArr = tenantRolesSelector(store.getState(), {
      tenantId,
    })

    const prevRoleAssignments = prevRoleAssignmentsArr.reduce(
      (acc, roleAssignment) => ({
        ...acc,
        [pathStr('user.id', roleAssignment)]: pathStr('role.id', roleAssignment),
      }),
      {},
    )
    const mergedUserIds = keys({ ...prevRoleAssignments, ...roleAssignments })

    // Perform the api calls to update the tenant and the user/role assignments
    const updateTenantPromise = keystone.updateProject(tenantId, {
      name,
      description,
    })
    const updateUserRolesPromises = mergedUserIds.map((userId) => {
      const prevRoleId = prevRoleAssignments[userId]
      const currRoleId = roleAssignments[userId]
      if (prevRoleId && !currRoleId) {
        // Remove unselected user/role pair
        return keystone.deleteUserRole({ tenantId, userId, roleId: prevRoleId }).then(always(null))
      } else if (!prevRoleId && currRoleId) {
        // Add new user and role
        return keystone.addUserRole({ tenantId, userId, roleId: currRoleId })
      } else if (prevRoleId && currRoleId && prevRoleId !== currRoleId) {
        // Update changed role (delete current and add new)
        return keystone
          .deleteUserRole({ tenantId, userId, roleId: prevRoleId })
          .then(() => keystone.addUserRole({ tenantId, userId, roleId: currRoleId }))
      }
      return Promise.resolve(null)
    }, [])
    // Resolve tenant and user/roles operation promises and filter out null responses
    const [updatedTenant] = await Promise.all([
      updateTenantPromise,
      tryCatchAsync(
        () => Promise.all(updateUserRolesPromises).then(reject(isNil)),
        (err) => {
          console.warn((err || {}).message)
          return emptyArr
        },
      )(null),
    ])
    const users = usersSelector(store.getState(), {})
    listTenantRoleAssignments.call({ tenantId })

    const userKeys = Object.keys(roleAssignments)
    trackEvent('Update Tenant', { tenantId })
    return {
      ...updatedTenant,
      users: users.filter((user) => userKeys.includes(user.id)),
    }
  }),
)

export const deleteTenant = tenantActions.add(
  new DeleteAction<DataKeys.ManagementTenants, { id: string }>(async ({ id }) => {
    Bugsnag.leaveBreadcrumb('Attempting to delete tenant', { tenantId: id })
    trackEvent('Delete Tenant', { tenantId: id })
    await keystone.deleteProject(id)
  }),
)

// These properties were part of old management tenant actions, not sure what to do
// with them with the ActionsSet.getInstance
// requiredRoles: 'admin',
