import { isNumeric } from 'utils/misc'
import { ServiceDetail } from 'api-client/model'
import { switchCase } from 'utils/fp'
import { BadgeVariant } from 'core/elements/badge/Badge'

export enum TaskStates {
  Ready = 'ready',
  Error = 'error',
  Suspended = 'suspended',
  Resuming = 'resuming',
  ResumeError = 'resume_error',
  SuspendError = 'suspend_error',
  HostUpgradeError = 'host_upgrade_error',
}

export const convertTaskStateToStatus = (taskState): BadgeVariant =>
  switchCase(
    {
      [TaskStates.Ready]: 'success',
      [TaskStates.Error]: 'error',
      [TaskStates.Suspended]: 'primary',
      [TaskStates.Resuming]: 'primary',
      [TaskStates.ResumeError]: 'error',
      [TaskStates.SuspendError]: 'error',
    },
    'unknown',
  )(taskState)

export const isSystemHealthy = (taskState, serviceDetails = {}) => {
  if ([TaskStates.Error, TaskStates.ResumeError, TaskStates.SuspendError].includes(taskState)) {
    return false
  }
  for (const service of Object.values<ServiceDetail>(serviceDetails)) {
    if (service.ready < service.desired) {
      return false
    }
  }
  return true
}

export const isServiceHealthy = ({ desired, ready }) =>
  isNumeric(desired) && isNumeric(ready) && desired > 0 && desired === ready

export const isSystemResuming = (state) => ['suspended', 'resuming'].includes(state)

export const getServiceMessage = (desired, ready) => {
  if (!isNumeric(desired) || !isNumeric(ready)) {
    return ''
  }
  if (desired === ready) {
    return `all ${desired} services are running`
  } else if (ready === 0) {
    return `none of the ${desired} services are running`
  }
  return `only ${ready} of the ${desired} services are running`
}

export const summaryMessages = {
  [TaskStates.Ready]: 'Your management plane is operational',
  [TaskStates.Error]: 'Your management plane is in a failed state',
  [TaskStates.Suspended]: 'Your management plane has been hibernated',
  [TaskStates.Resuming]: 'Your management plane has been hibernated',
  [TaskStates.ResumeError]: 'Your management plane is in a failed state',
  [TaskStates.SuspendError]: 'Your management plane is in a failed state',
  [TaskStates.HostUpgradeError]:
    'Your management plane is operational, You have host(s) with a failed upgrade',
  default: 'Your management plane is in an unknown state',
}
export const detailMessages = {
  [TaskStates.Ready]: 'The Platform9 SaaS Managment Plane is active and operational',
  [TaskStates.Error]:
    'A fatal error has occured, please contact your Platform9 administrator for help',
  [TaskStates.Suspended]:
    'The Platform9 SaaS Managment Plane was hibernated and is in the process of resuming. Please wait a few minutes for the process to complete',
  [TaskStates.Resuming]:
    'The Platform9 SaaS Managment Plane was hibernated and is in the process of resuming. Please wait a few minutes for the process to complete',
  [TaskStates.ResumeError]:
    'A fatal error has occured while resuming the Platform9 SaaS Managment Plane, please contact your Platform9 administrator for help',
  [TaskStates.SuspendError]:
    'A fatal error has occured while suspending the Platform9 SaaS Managment Plane, please contact your Platform9 administrator for help',
  [TaskStates.HostUpgradeError]:
    "This happens when you have any hosts (one or more) that aren't responding, or otherwise failed to upgrade. Please contact your Platform9 administrator for help",
  default:
    'The Platform9 SaaS Managment Plane is in an unknown state. It\'s current state is: "{$1}"',
}

export const SuspendResumeMessages = {
  getSummaryMessage(name) {
    if (summaryMessages[name]) {
      return summaryMessages[name]
    }
    return summaryMessages.default
  },
  getDetailMessage(name) {
    if (detailMessages[name]) {
      return detailMessages[name]
    }
    return detailMessages.default.replace('{$1}', name)
  },
}
