// Libs
import React from 'react'
import { useSelector } from 'react-redux'
import { prop } from 'ramda'
import { makeStyles } from '@material-ui/styles'

// Types
import Theme from 'core/themes/model'
import { RootState } from 'app/store'
import { ClientState, clientStoreKey } from 'core/client/clientReducers'

// Components
import ServiceCard from './service-card'
import Summary from './summary'

export default function AccountStatus() {
  const classes = useStyles()
  const { systemStatus } = useSelector<RootState, ClientState>(prop(clientStoreKey))
  const elems = Object.entries(systemStatus?.serviceDetails)?.map(([name, details]) => (
    <ServiceCard key={name} name={name} details={details} taskState={systemStatus?.taskState} />
  ))
  return (
    <>
      <Summary taskState={systemStatus?.taskState} />
      <div className={classes.cards}>{elems}</div>
    </>
  )
}

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  cards: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, 300px)',
    gap: '16px',
    justifyContent: 'center',
    alignContent: 'start',
  },
}))
