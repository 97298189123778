import React from 'react'
import AsyncDropdown, { PropsWithAsyncDropdown } from 'core/elements/dropdown/AsyncDropdown'
import { SsoProviders } from './model'

const items = [
  { label: 'Google', value: SsoProviders.Google },
  { label: 'Okta', value: SsoProviders.Okta },
  { label: 'OneLogin', value: SsoProviders.OneLogin },
  { label: 'Other', value: SsoProviders.Other },
]

interface Props {}

export default function SsoProviderPicklist(props: PropsWithAsyncDropdown<Props>) {
  return (
    <AsyncDropdown
      name="ssoProvider"
      label="SSO Provider"
      items={items}
      showAll={false}
      compact
      {...props}
    />
  )
}
