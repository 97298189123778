import React from 'react'
import AsyncDropdown, { PropsWithAsyncDropdown } from 'core/elements/dropdown/AsyncDropdown'

interface Props {}

export enum Criteria {
  Contains = 'any_one_of',
  DoesNotContain = 'not_any_of',
}

const items = [
  { label: 'Contains', value: Criteria.Contains },
  { label: 'Does not contain', value: Criteria.DoesNotContain },
]

export default function CriteriaPicklist({
  name = 'criteria',
  label = 'Criteria',
  selectFirst = true,
  ...rest
}: PropsWithAsyncDropdown<Props>) {
  return (
    <AsyncDropdown
      name={name}
      label={label}
      selectFirst={selectFirst}
      items={items}
      showAll={false}
      {...rest}
    />
  )
}
