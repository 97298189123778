import React, { useCallback, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import ValidatedForm from 'core/components/validatedForm/ValidatedForm'
import { FormFieldCard } from 'core/components/validatedForm/FormFieldCard'
import Text from 'core/elements/Text'
import DocumentMeta from 'core/components/DocumentMeta'
import SsoToggle from './SsoToggle'
import TextField from 'core/components/validatedForm/TextField'
import SsoProviderPicklist from './SsoProviderPicklist'
import CodeMirror from 'core/components/validatedForm/CodeMirrorField'
import UploadSamlMetadataLink from './UploadSamlMetadataLink'
import Button from 'core/elements/button'
import CopyToClipboard from 'core/components/CopyToClipboard'
import useParams from 'core/hooks/useParams'
import SubmitButton from 'core/components/buttons/SubmitButton'
import DropdownField from 'core/components/validatedForm/DropdownField'
import { createSsoConfig, deleteSsoConfig, loadSsoConfig } from './actions'
import Progress from 'core/components/progress/Progress'
import SsoEnabledDialog from './SsoEnabledDialog'
import { SsoProviders } from './model'
import AccountUpgradeDialog from '../../theme/AccountUpgradeDialog'
import { CustomerTiers, ssoEnabledTiers } from 'app/constants'
import { pathOr, prop } from 'ramda'
import { useSelector } from 'react-redux'
import { SessionState, sessionStoreKey } from 'core/session/sessionReducers'
import { RootState } from 'app/store'
import { trackEvent } from 'utils/tracking'

const useStyles = makeStyles((theme: Theme) => ({
  validatedFormContainer: {
    display: 'grid',
    gridGap: theme.spacing(2),
  },
  wizardLabel: {
    margin: theme.spacing(1, 0),
  },
  fullWidth: {
    width: '100% !important',
  },
  spaceBelow: {
    marginBottom: theme.spacing(1.5),
  },
  conditionalFields: {
    borderTop: `1px solid ${theme.components.card.border}`,
    marginTop: 24,
    paddingTop: 16,
    display: 'grid',
    gridGap: theme.spacing(1),
  },
  field: {
    display: 'flex',
    alignItems: 'center',
  },
  orLabel: {
    marginRight: 24,
  },
  attributeMapButtons: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridAutoColumns: 'max-content',
    gridGap: theme.spacing(2),
  },
  copyIcon: {
    marginRight: theme.spacing(0.5),
  },
  copyButton: {
    marginLeft: theme.spacing(4),
  },
  filename: {
    marginLeft: theme.spacing(0.5),
  },
  error: {
    color: theme.palette.red[500],
  },
  card: {
    overflowY: 'auto',
    maxWidth: 'inherit',
  },
}))

type State = {
  enableSso: boolean
  ssoIsEnabled: boolean
  ssoProviderName: string
  defaultAttributeMap: string
  entityId: string
  ssoProvider: string
  metadataUrl?: string
  metadata?: string
  metadataFileName?: string
}

const customCodeMirrorOptions = {
  mode: 'xml',
}

const updateSsoSettings = (data, setLoading, setDialogOpened, updateParams) => {
  // Validate that only metadataUrl or metadata is defined
  if (data.metadataUrl && data.metadata) {
    return
  }

  const sendRequests = async () => {
    // If SSO config already exists, delete old one and create a new one
    // Otherwise just create a new one
    // Need to do this until backend provides an update method
    if (data.ssoIsEnabled) {
      await deleteSsoConfig()
    }

    const body = {
      provider: data.ssoProvider === SsoProviders.Other ? data.ssoProviderName : data.ssoProvider,
      entity_id: data.entityId,
      metadata_url: data.metadataUrl,
      metadata: data.metadataUrl ? undefined : data.metadata,
      attr_map_xml: data.defaultAttributeMap,
    }
    setLoading(true)
    try {
      await createSsoConfig(body)
      updateParams({ ssoIsEnabled: true })
      // Show user a dialog saying sso configuration successful
      setDialogOpened(true)
    } finally {
      setLoading(false)
    }
  }

  sendRequests()
}

const SsoPage = () => {
  const classes = useStyles({})
  const [loading, setLoading] = useState(true)
  const [dialogOpened, setDialogOpened] = useState(false)
  const [upgradeDialogOpened, setUpgradeDialogOpened] = useState(false)
  const session = useSelector<RootState, SessionState>(prop(sessionStoreKey))
  const { features } = session
  const { params, updateParams, getParamsUpdater } = useParams<State>({
    enableSso: false,
    ssoIsEnabled: false,
    defaultAttributeMap: '',
    entityId: '',
    ssoProvider: '',
    ssoProviderName: '',
  })

  useEffect(() => {
    const getSettings = async () => {
      try {
        const {
          attr_map_xml: defaultAttributeMap,
          entity_id: entityId,
          provider: ssoProvider,
          metadata_url: metadataUrl,
          metadata,
        }: any = await loadSsoConfig()
        updateParams({
          defaultAttributeMap,
          entityId,
          ssoProvider,
          metadataUrl,
          metadata,
          enableSso: true,
          ssoIsEnabled: true,
        })
      } catch (err) {
        console.log(err, 'error')
        updateParams({ ssoIsEnabled: false })
      }
      setLoading(false)
    }
    getSettings()
  }, [])

  const toggleSso = useCallback(async () => {
    if (params.enableSso && params.ssoIsEnabled) {
      await deleteSsoConfig()
      updateParams({ enableSso: false, ssoIsEnabled: false })
      return
    }
    if (
      !params.enableSso &&
      !ssoEnabledTiers.includes(pathOr(CustomerTiers.Freedom, ['customer_tier'], features))
    ) {
      // If SSO is not available for customer tier
      setUpgradeDialogOpened(true)
      trackEvent('FT User - Tried to Enable SSO', {
        duDomain: window.location.origin,
      })
      return
    }
    updateParams({ enableSso: !params.enableSso })
  }, [params, updateParams, deleteSsoConfig])

  return (
    <>
      <DocumentMeta title="SSO Management" bodyClasses={['form-view']} />
      {upgradeDialogOpened && (
        <AccountUpgradeDialog
          feature="Enterprise SSO"
          onClose={() => setUpgradeDialogOpened(false)}
        />
      )}
      <Progress loading={loading}>
        <ValidatedForm
          classes={{ root: classes.validatedFormContainer }}
          elevated={false}
          formActions={<>{params.enableSso && <SubmitButton>Save</SubmitButton>}</>}
          onSubmit={() => updateSsoSettings(params, setLoading, setDialogOpened, updateParams)}
        >
          <FormFieldCard className={classes.card}>
            <SsoToggle
              ssoIsEnabled={params.ssoIsEnabled}
              checked={params.enableSso}
              onClick={toggleSso}
            />
            <Text variant="body2" className={classes.spaceBelow}>
              Enterprise Single Sign On supports SAML 2.0 identity integration for seamless access
              to your Platform9 instance.
            </Text>
            {params.enableSso && (
              <div className={classes.conditionalFields}>
                <DropdownField
                  DropdownComponent={SsoProviderPicklist}
                  id="ssoProvider"
                  label="SSO Provider"
                  onChange={getParamsUpdater('ssoProvider')}
                  value={params.ssoProvider}
                  required
                />
                {params.ssoProvider === SsoProviders.Other && (
                  <TextField
                    id="ssoProviderName"
                    label="SSO Provider Name"
                    onChange={getParamsUpdater('ssoProviderName')}
                    value={params.ssoProviderName}
                    info="Provide a name to identify your SSO provider"
                    required
                  />
                )}
                <Text variant="caption1" className={classes.wizardLabel}>
                  Entity Endpoint for your SSO Provider
                </Text>
                <TextField
                  id="entityId"
                  label="Entity ID"
                  onChange={getParamsUpdater('entityId')}
                  value={params.entityId}
                  required
                />
                <Text variant="caption1" className={classes.wizardLabel}>
                  SAML Metadata (XML) from your SSO Provider
                  <Text variant="body2">
                    You may specify a metadata URL or upload a metadata XML file.
                  </Text>
                </Text>
                <TextField
                  id="metadataUrl"
                  label="URL"
                  onChange={getParamsUpdater('metadataUrl')}
                  value={params.metadataUrl}
                  required={!params.metadata}
                />
                <div className={classes.field}>
                  <Text variant="body2" className={classes.orLabel}>
                    or
                  </Text>
                  <UploadSamlMetadataLink
                    id="metadata"
                    onChange={getParamsUpdater('metadata')}
                    fileNameUpdater={getParamsUpdater('metadataFileName')}
                  />
                  <Text variant="body2" className={classes.filename}>
                    {params.metadataFileName}
                  </Text>
                </div>
                <CodeMirror
                  id="metadata"
                  label="SAML Metadata (XML)"
                  options={customCodeMirrorOptions}
                  onChange={getParamsUpdater('metadata')}
                  value={params.metadata}
                  className={classes.fullWidth}
                  required={!params.metadataUrl}
                />
                {params.metadataUrl && params.metadata && (
                  <Text variant="body2" className={classes.error}>
                    Please specify only the metadata URL or only the metadata XML
                  </Text>
                )}
                <Text variant="caption1" className={classes.wizardLabel}>
                  SSO Provider Attribute MAP in XML
                </Text>
                <Text variant="body2">
                  Enterprise Single Sign on supports SAML 2.0 identity integration for seamless
                  access to your Platform9 instance.
                </Text>
                <CodeMirror
                  id="defaultAttributeMap"
                  label="Default Attribute Map"
                  options={customCodeMirrorOptions}
                  onChange={getParamsUpdater('defaultAttributeMap')}
                  value={params.defaultAttributeMap}
                  className={classes.fullWidth}
                  required
                />
                <div className={classes.attributeMapButtons}>
                  <Button
                    variant="secondary"
                    type="button"
                    onClick={() => updateParams({ defaultAttributeMap: '' })}
                  >
                    Clear XML
                  </Button>
                  <CopyToClipboard
                    copyText={params.defaultAttributeMap}
                    copyIcon={false}
                    inline={false}
                    triggerWithChild
                  >
                    {
                      // @ts-ignore
                      <Button type="button" icon="copy">
                        Copy Attribute Map
                      </Button>
                    }
                  </CopyToClipboard>
                </div>
              </div>
            )}
          </FormFieldCard>
          {dialogOpened && <SsoEnabledDialog onClose={() => setDialogOpened(false)} />}
        </ValidatedForm>
      </Progress>
    </>
  )
}

export default SsoPage
