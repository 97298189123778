import ApiClient from 'api-client/ApiClient'
import DataKeys, { entityNamesByKey } from 'k8s/DataKeys'
import Bugsnag from 'utils/bugsnag'
import ActionsSet from 'core/actions/ActionsSet'
import ListAction from 'core/actions/ListAction'

const { keystone } = ApiClient.getInstance()

export const mngmRoleActions = ActionsSet.make<DataKeys.ManagementRoles>({
  uniqueIdentifier: 'id',
  entityName: entityNamesByKey.ManagementRoles,
  cacheKey: DataKeys.ManagementRoles,
})

export const listMngmRoles = mngmRoleActions.add(
  new ListAction<DataKeys.ManagementRoles>(async () => {
    Bugsnag.leaveBreadcrumb('Attempting to get management roles')
    return keystone.getRoles()
  }),
)
