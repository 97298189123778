import React from 'react'
import Text from 'core/elements/Text'
import Button from 'core/elements/button'
import Modal from 'core/elements/modal'

interface Props {
  onClose: () => void
}

const MFAEnabledDialog = ({ onClose }: Props) => {
  return (
    <Modal
      panel="dialog"
      open
      onClose={onClose}
      footer={<Button onClick={onClose}>Confirm</Button>}
    >
      <Text variant="body2">
        MFA configuration saved successfully. You will be logged out and need to log back in using
        MFA.
      </Text>
    </Modal>
  )
}

export default MFAEnabledDialog
