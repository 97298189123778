import {
  tenantRolesSelector,
  tenantsSelector,
} from 'account/components/userManagement/tenants/selectors'
import UserRolesTableField from 'account/components/userManagement/tenants/UserRolesTableField'
import { listUsers } from 'account/components/userManagement/users/new-actions'
import { usersSelector } from 'account/components/userManagement/users/selectors'
import FormWrapper from 'core/components/FormWrapper'
import TextField from 'core/components/validatedForm/TextField'
import ValidatedForm from 'core/components/validatedForm/ValidatedForm'
import Wizard from 'core/components/wizard/Wizard'
import WizardStep from 'core/components/wizard/WizardStep'
import useListAction from 'core/hooks/useListAction'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { requiredValidator } from 'core/utils/fieldValidators'
import { routes } from 'core/utils/routes'
import { propEq } from 'ramda'
import React, { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router'
import useReactRouter from 'use-react-router'
import { emptyObj, pathStr } from 'utils/fp'
import {
  listTenantRoleAssignments,
  listTenants,
  listUserTenants,
  updateTenant,
} from './new-actions'

const listUrl = routes.userManagement.root.path({ tab: 'tenants' })

const wizardMetaFormattedNames = {
  name: 'Name',
  description: 'Description',
  roleAssignments: 'Role Assignments',
}

const wizardMetaCalloutFields = Object.keys(wizardMetaFormattedNames)

const userRolesValidations = [requiredValidator.withMessage('Must select at least one user')]

const EditTenantPage = () => {
  const { match, history } = useReactRouter()
  const tenantId = match.params.id

  const { reload: reloadUserTenants } = useListAction(listUserTenants)

  const { loading: loadingTenants } = useListAction(listTenants)
  const tenants = useSelector(tenantsSelector)

  const tenant = useMemo(() => tenants.find(propEq('id', tenantId)) || emptyObj, [
    tenants,
    tenantId,
  ])

  const { update, updating, error: errorUpdatingTenant } = useUpdateAction(updateTenant)

  const { loading: loadingUsers } = useListAction(listUsers)
  const users = useSelector(usersSelector)

  const submitForm = async (params) => {
    const { success } = await update(params)

    if (success) {
      reloadUserTenants(true)
      history.push(listUrl)
    }
  }

  // Question about the new actions here:
  // Had to add this bc it was never getting called otherwise, why is that?
  // Is it because tenant roles are not part of a ListContainer? Maybe
  // ListContainer is handling the .call?
  useEffect(() => {
    listTenantRoleAssignments.call({ tenantId })
  }, [])

  const { loading: loadingRoleAssignments } = useListAction(listTenantRoleAssignments, {
    params: { tenantId },
    initialLoadingState: true,
  })
  const roleAssignments = useSelectorWithParams(tenantRolesSelector, { tenantId })

  const initialContext = useMemo(
    () => ({
      id: tenantId,
      name: tenant.name,
      description: tenant.description || '',
      roleAssignments: roleAssignments.reduce(
        (acc, roleAssignment) => ({
          ...acc,
          [pathStr('user.id', roleAssignment)]: pathStr('role.id', roleAssignment),
        }),
        {},
      ),
    }),
    [tenant, roleAssignments],
  )
  const loadingSomething = loadingUsers || loadingTenants || loadingRoleAssignments || updating

  if (tenant.name === 'service') {
    return <Redirect to={listUrl} />
  }

  return (
    <FormWrapper
      title={`Edit Tenant ${tenant.name || ''}`}
      loading={loadingSomething}
      renderContentOnMount={!loadingSomething}
      message={updating ? 'Submitting form...' : 'Loading Tenant...'}
      backUrl={listUrl}
    >
      <Wizard onComplete={submitForm} context={initialContext} error={errorUpdatingTenant}>
        {({ wizardContext, setWizardContext, onNext }) => (
          <>
            <WizardStep
              stepId="basic"
              label="Basic Info"
              summaryTitle={`Edit Tenant ${tenant.name || ''}`}
              summaryKeyOverrides={wizardMetaFormattedNames}
              summaryCalloutFields={wizardMetaCalloutFields}
            >
              <ValidatedForm
                title="Basic Info"
                initialValues={wizardContext}
                onSubmit={setWizardContext}
                triggerSubmit={onNext}
              >
                <TextField id="name" label="Name" required />
                <TextField id="description" label="Description" />
              </ValidatedForm>
            </WizardStep>
            <WizardStep stepId="users" label="Users and Roles">
              <ValidatedForm
                title="Users and Roles"
                fullWidth
                initialValues={wizardContext}
                onSubmit={setWizardContext}
                triggerSubmit={onNext}
              >
                <UserRolesTableField
                  validations={userRolesValidations}
                  id="roleAssignments"
                  users={users}
                />
              </ValidatedForm>
            </WizardStep>
          </>
        )}
      </Wizard>
    </FormWrapper>
  )
}

export default EditTenantPage
