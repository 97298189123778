import UserRolesListPage from 'account/components/userManagement/roles/UserRolesListPage'
import TenantsListPage from 'account/components/userManagement/tenants/TenantsListPage'
import UsersListPage from 'account/components/userManagement/users/UsersListPage'
import { dashboardUrl } from 'app/constants'
import DocumentMeta from 'core/components/DocumentMeta'
import PassiveHeaderLink from 'core/components/passive-header-link'
import { HeaderTitlePortal } from 'core/elements/header/portals'
import Tabs from 'core/elements/tabs'
import Tab from 'core/elements/tabs/Tab'
import { routes } from 'core/utils/routes'
import React from 'react'
import UserGroupsListPage from './groups/UserGroupsListPage'

export default function UserManagementIndexPage() {
  return (
    <>
      <DocumentMeta title="Tenants & Users" />
      <HeaderTitlePortal>
        <PassiveHeaderLink icon="chevron-left" text="Back to Dashboard" url={dashboardUrl} />
      </HeaderTitlePortal>
      <Tabs route={routes.userManagement.root}>
        <Tab value="tenants" label="Tenants">
          <TenantsListPage />
        </Tab>
        <Tab value="users" label="Users">
          <UsersListPage />
        </Tab>
        <Tab value="roles" label="Roles">
          <UserRolesListPage />
        </Tab>
        <Tab value="groups" label="Groups">
          <UserGroupsListPage />
        </Tab>
      </Tabs>
    </>
  )
}
