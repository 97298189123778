import React from 'react'
import Text from 'core/elements/Text'
import Button from 'core/elements/button'
import Modal from 'core/elements/modal'

interface Props {
  onClose: () => void
}

export default function SsoEnabledDialog({ onClose }: Props) {
  return (
    <Modal open panel="dialog" onClose={onClose} footer={<Button onClick={onClose}>OK</Button>}>
      <Text variant="body2">SSO configuration saved successfully.</Text>
    </Modal>
  )
}
