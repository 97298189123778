import React from 'react'
import Button from 'core/elements/button'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import { CustomerTiers, upgradeLinks } from 'app/constants'
import ExternalLink from 'core/components/ExternalLink'
import { trackUpgrade } from 'utils/tracking'

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  noUnderline: {
    '&:hover': {
      textDecoration: 'none',
    },
  },
}))

interface Props {
  customerTier: CustomerTiers
  username: string
}

const UpgradeButton = ({ customerTier, username }: Props) => {
  const classes = useStyles({})
  return (
    <ExternalLink url={upgradeLinks[customerTier]} className={classes.noUnderline}>
      <Button
        onClick={() => trackUpgrade({ username, customerTier })}
        type="button"
        variant="cta"
        icon="crown"
      >
        Upgrade Now
      </Button>
    </ExternalLink>
  )
}

export default UpgradeButton
