import React, { useMemo, useEffect } from 'react'
import { isEmpty, propOr, head } from 'ramda'
import { projectAs } from 'utils/fp'
import { allKey } from 'app/constants'
import Dropdown from 'core/elements/dropdown'
import { MultiDropdownProps } from 'core/elements/dropdown/MultiDropdown'
import withProgress from 'core/components/progress/withProgress'
import useListAction from 'core/hooks/useListAction'
import { listMngmRoles } from '../roles/new-actions'
import { useSelector } from 'react-redux'
import { makeManagementRolesSelector } from '../roles/selectors'

interface RolesPicklistProps<V> extends Omit<MultiDropdownProps<V>, 'items'> {
  name: string
  allRoles?: boolean
  selectFirst?: boolean
}

const AsyncDropdown = withProgress(Dropdown, { inline: true })

export default function RolesPicklist<V>({
  allRoles,
  loading,
  onChange,
  value: values,
  selectFirst,
  disabled,
  name = 'roles',
  label = 'Roles',
  ...rest
}: RolesPicklistProps<V>) {
  const { loading: loadingRoles } = useListAction(listMngmRoles)
  const roles = useSelector(makeManagementRolesSelector({ allRoles }))

  const options = useMemo(() => projectAs({ label: 'displayName', value: 'id' }, roles), [roles])

  // Select the first role as soon as roles are loaded
  useEffect(() => {
    if (selectFirst && !(disabled || values || isEmpty(options))) {
      onChange(propOr(allKey, 'value', head(options)))
    }
    // workaround for memoized updateFieldValue in TenantRolesTableField component
    if (values) {
      onChange(values)
    }
  }, [options, disabled, selectFirst, values])

  return (
    <AsyncDropdown
      {...rest}
      name={name}
      label={label}
      compact
      disabled={disabled}
      value={values}
      onChange={onChange}
      loading={loading || loadingRoles}
      items={options}
      data-testid={'roles-picklist'}
    />
  )
}
