import React, { useState, useCallback } from 'react'
import SimpleLink from 'core/components/SimpleLink'
import Button from 'core/elements/button'
import Text from 'core/elements/Text'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import { useDropzone } from 'react-dropzone'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import withFormContext from 'core/components/validatedForm/withFormContext'
import Modal from 'core/elements/modal'

interface Props {
  onChange: (value: any) => void
  fileNameUpdater: (value: any) => void
}

const useStyles = makeStyles((theme: Theme) => ({
  dialogContainer: {
    padding: theme.spacing(1, 3),
  },
  dialogHeader: {
    padding: theme.spacing(1, 0),
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    height: 208,
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  uploadIcon: {
    fontSize: 40,
    color: theme.palette.grey[300],
  },
  dropzoneText: {
    margin: theme.spacing(2, 0, 1),
  },
}))

export default withFormContext<string, Props>(function UploadSamlMetadataLink({
  onChange,
  fileNameUpdater,
}: Props) {
  const classes = useStyles({})
  const [showModal, setModal] = useState(false)

  const handleOpen = () => setModal(true)
  const handleClose = () => setModal(false)

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0]
    const reader = new FileReader()

    reader.onabort = () => console.log('file reading was aborted')
    reader.onerror = () => console.log('file reading has failed')
    reader.onload = () => {
      const text = reader.result
      onChange(text)
      fileNameUpdater(file.name)
      handleClose()
    }
    reader.readAsText(file)
  }, [])
  const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: '.xml', maxFiles: 1 })

  const renderModalContent = () => (
    <Modal
      open
      panel="dialog"
      onClose={handleClose}
      title="Upload SAML Metadata (XML) from your SSO Provider"
      maxWidth={600}
    >
      <div className={classes.dialogContent} {...getRootProps()}>
        <input {...getInputProps()} />
        <div>
          <FontAwesomeIcon className={classes.uploadIcon}>upload</FontAwesomeIcon>
          <div className={classes.dropzoneText}>
            <Text variant="body2">Drag and drop file (.xml) here</Text>
            <Text variant="body2">or</Text>
          </div>
          <Button variant="secondary">Browse</Button>
        </div>
      </div>
    </Modal>
  )

  return (
    <div>
      {showModal && renderModalContent()}
      <SimpleLink src="" onClick={handleOpen}>
        <Button>Upload File</Button>
      </SimpleLink>
    </div>
  )
})
