import { makeStyles } from '@material-ui/styles'
import Button from 'core/elements/button'
import Modal from 'core/elements/modal'
import Text from 'core/elements/Text'
import ToggleSwitch from 'core/elements/ToggleSwitch'
import React, { useState } from 'react'

interface Props {
  ssoIsEnabled: boolean
  checked: boolean
  onClick?: any
}

const useStyles = makeStyles(() => ({
  switch: {
    marginBottom: 8,
  },
}))

const SsoToggle = ({ ssoIsEnabled, checked, onClick }: Props) => {
  const classes = useStyles({})
  const [showModal, setModal] = useState(false)

  const handleOpen = () => setModal(true)
  const handleClose = () => setModal(false)

  const toggleSwitch = () => {
    if (checked && ssoIsEnabled) {
      handleOpen()
      return
    }
    onClick()
  }

  const disableSso = async () => {
    await onClick()
    handleClose()
  }

  const renderModalContent = () => (
    <Modal
      panel="dialog"
      open
      onClose={handleClose}
      title="Disable SSO"
      footer={
        <>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={disableSso}>Disable</Button>
        </>
      }
    >
      <Text variant="body2">Are you sure you would like to disable SSO?</Text>
    </Modal>
  )

  return (
    <div>
      {showModal && renderModalContent()}
      <ToggleSwitch
        className={classes.switch}
        active={checked}
        onClick={toggleSwitch}
        label="Enable SSO"
      />
    </div>
  )
}

export default SsoToggle
