import React from 'react'
import { makeStyles } from '@material-ui/styles'

// Types
import Theme from 'core/themes/model'
import { ServiceDetail } from 'api-client/model'

// Helpers
import { isServiceHealthy, isSystemResuming } from './helpers'
import Text from 'core/elements/Text'
import Card from 'core/elements/card'
import Badge from 'core/elements/badge'

interface Props {
  name: string
  details: ServiceDetail
  taskState: string
}

export default function ServiceCard({ name, details, taskState }: Props) {
  const isHealthy = isServiceHealthy(details)
  const isResuming = isSystemResuming(taskState)
  const readyHealth = isResuming ? 'primary' : isHealthy ? 'success' : 'error'
  const readyName = isResuming ? 'Resuming' : isHealthy ? 'Healthy' : 'Failed'
  const classes = useStyles({ readyHealth })
  return (
    <Card title={name} withCustomBody>
      <div className={classes.body}>
        <div className={classes.statBreakdown}>
          <Text variant="body1" component="div" className={classes.stat}>
            <span>{details?.desired}</span>
            <i>/</i>
            <span>{details?.ready}</span>
          </Text>
          <Text variant="body2" className={classes.statText}>
            <span>Desired</span>
            <span>Ready</span>
          </Text>
        </div>
        <Badge text={readyName} variant={readyHealth} />
      </div>
    </Card>
  )
}

const useStyles = makeStyles<Theme, { readyHealth: string }>((theme: Theme) => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  body: {
    display: 'grid',
    gridTemplateColumns: '1fr max-content',
    padding: '24px 32px',
    justifyItems: 'start',
  },
  statBreakdown: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    gap: 8,
  },
  stat: {
    fontSize: 40,
    lineHeight: '36px',
    display: 'grid',
    gridTemplateColumns: '1fr max-content 1fr',
    '& > span': {
      minWidth: 50,
    },
  },
  statText: {
    display: 'grid',
    gridTemplateColumns: 'max-content max-content',
    justifyContent: 'space-between',
  },
}))
