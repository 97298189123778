import React, { useState } from 'react'
import Button from 'core/elements/button'
import Modal from 'core/elements/modal'
import Text from 'core/elements/Text'
import ToggleSwitch from 'core/elements/ToggleSwitch'

interface Props {
  mfaIsEnabled: boolean
  checked: boolean
  onClick?: any
  className?: string
}

const MFAToggle = ({ mfaIsEnabled, checked, onClick, className = '' }: Props) => {
  const [showModal, setModal] = useState(false)

  const handleOpen = () => setModal(true)
  const handleClose = () => setModal(false)

  const toggleSwitch = () => {
    if (checked && mfaIsEnabled) {
      handleOpen()
      return
    }
    onClick()
  }

  const disableMFA = async () => {
    await onClick()
    handleClose()
  }

  const renderModalContent = () => (
    <Modal
      panel="dialog"
      open
      onClose={handleClose}
      title="Disable MFA"
      footer={
        <>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button onClick={disableMFA}>Disable</Button>
        </>
      }
    >
      <Text variant="body2">Are you sure you would like to disable MFA?</Text>
      <Text variant="body2">
        To re-enable in the future, it will require re-configuring MFA again.
      </Text>
    </Modal>
  )

  return (
    <div className={className}>
      {showModal && renderModalContent()}
      <ToggleSwitch
        active={checked}
        onClick={toggleSwitch}
        label="Enable Multifactor Authentication"
      />
    </div>
  )
}

export default MFAToggle
