import Theme from 'core/themes/model'
import { Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import React, { useMemo } from 'react'
import Text from 'core/elements/Text'
import { pathStr } from 'utils/fp'
import { listUserTenants } from '../userManagement/tenants/new-actions'
import useListAction from 'core/hooks/useListAction'
import { userTenantsSelector } from 'account/components/userManagement/tenants/selectors'
import { useSelector } from 'react-redux'
import { listUserRoleAssignments } from 'account/components/userManagement/users/new-actions'
import { userRolesSelector } from 'account/components/userManagement/users/selectors'
import useSelectorWithParams from 'core/hooks/useSelectorWithParams'

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    margin: theme.spacing(0, 1, 2, 1),
    '& th.MuiTableCell-head': {
      borderBottom: `1px solid ${theme.components.card.border}`,
    },
  },
}))

const TenantsAndRoleAccessTable = ({ userId }) => {
  const classes = useStyles()

  const { loading: loadingUserTenants } = useListAction(listUserTenants)
  const userTenants = useSelector(userTenantsSelector)

  const { loading: loadingRoleAssignments } = useListAction(listUserRoleAssignments, {
    params: { userId },
  })
  const roleAssignments = useSelectorWithParams(userRolesSelector, { userId })

  const tenantsAndRoles = useMemo(
    () =>
      userTenants.map((tenant) => {
        const userRoleAssignments = roleAssignments.find(
          (roleAssignment) => tenant.id === pathStr('scope.project.id', roleAssignment),
        )
        return {
          ...tenant,
          roleName: pathStr('role.name', userRoleAssignments),
        }
      }),
    [userTenants, roleAssignments],
  )

  if (loadingUserTenants || loadingRoleAssignments) {
    return null
  }

  return (
    <Table className={classes.table} size="small" aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell>
            <Text variant="caption2">Tenant Name</Text>
          </TableCell>
          <TableCell>
            <Text variant="caption2">Tenant Description</Text>
          </TableCell>
          <TableCell>
            <Text variant="caption2">Role</Text>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {tenantsAndRoles.map((tenant) => (
          <TableRow key={tenant.id}>
            <TableCell>
              <Text variant="body2">{tenant.name}</Text>
            </TableCell>
            <TableCell>
              <Text variant="body2">{tenant.description}</Text>
            </TableCell>
            <TableCell>
              <Text variant="body2">{tenant.roleName}</Text>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

export default TenantsAndRoleAccessTable
